@import '../../../../assets/scss/variable.scss';

.report-card-head-sec {
  padding: 17px;
  max-width: 360px;
  .report-card-image {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
  }
  .card-person-name {
    font-size: 23px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    color: #f54d71;
    font-weight: 600;
  }
  .input-field-sec {
    .form-control-sec {
      width: -webkit-fill-available;
      margin: 0px;
      .form-input-field {
        // width: 0px;
        background-color: transparent;
        padding: 0px;
        .MuiFilledInput-input {
          padding: 0px;
        }
      }
      .helper-text {
        margin-left: 0px;
        color: $color-black;
        font-weight: 600;
      }
    }
  }
  .send-btn-sec {
    .send-btn {
      text-transform: capitalize;
      background-color: #f54d71;
      font-size: 15px;
      line-height: 17px;
      font-family: $regulerFont;
      margin: 33px 0px 40px 0px;
      border-radius: 25px;
      max-width: 134px;
      width: 100%;
      height: 36px;
    }
  }
}
.report {
  background-color: #fff !important;
}
