@import 'src/assets/scss/variable.scss';
body {
  .info-about-card-wrapper {
    border: 1px solid $color-white;
    box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
    border-radius: 10px;
    padding: 15px 12px 20px 12px;
    margin: 20px 1px;
    background: $color-white;
  }
  .info-options {
    border-right: 1px solid $color-black;
    padding: 5px;
    margin-top: 5px;
    height: 30px;
    align-items: center;
    &:last-child {
      border-right: none;
    }
    .info-option-text {
      width: max-content;
    }
  }
  .info-options-icon {
    margin-right: 10px;
  }
  .dog-deceased-icon {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: $color-black;
    margin-left: 10px;
    margin-right: 5px;
  }
  /* Hide volume control */
  #matchingVideo::-webkit-media-controls-volume-slider,
  #matchingVideo::-webkit-media-controls-mute-button {
    display: none;
  }

  /* Hide fullscreen control */
  #matchingVideo::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  /* Hide option menu control */
  #matchingVideo::-webkit-media-controls-overflow-button {
    display: none;
  }
  #matchingVideo::-webkit-media-controls-enclosure {
    overflow: hidden;
  }
  .info-containt-wrap {
    // user-select: none;
    // cursor: grab;
    ::-webkit-scrollbar {
      height: 3px;
      width: 3px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $color-gray;
      border-radius: 20px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $color-gray;
      border-radius: 20px;
    }
  }
  // .info-containt-wrap:active {
  //   cursor: grabbing;
  // }
}

/* Matching Image */
.matching-img-sec {
  position: relative;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // width: 100%;
  height: 230px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px #d9d9d9;
  margin: 20px 1px;
  background: $color-white;
  .matching-img {
    // max-width: 380px;
    // object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 230px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px #d9d9d9;
    background: $color-white;
  }
}
.dog-paw-icon {
  position: absolute;
  right: 3%;
  bottom: 4%;
  box-shadow: 0px 0px 1px $color-black;
  border-radius: 50%;
  display: flex;
}
/* Matching Image */

/* Video */
.matching-sec {
  position: relative;
  border-radius: 10px;
  height: 230px;
  background: $color-white;

  // .video-sec {
  .custom-video {
    width: 100%;
    border-radius: 10px;
    height: 230px;
    object-fit: cover;
  }
  // }
}

/* Video */

/* Matching Content */

.dog-content-sec {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // max-width: 380px;
  // width: 100%;
  // height: 129px;
  // margin-left: 3px;
  // margin-top: 20px;
  margin: 20px 1px;
  position: relative;
  background: $color-white;
  .dog-paw-icon {
    bottom: 13%;
  }
  .dog-details-content {
    // padding-top: 15.6px;
    // padding-left: 12px;
    padding: 15px;

    .details-txt {
      width: 88%;
    }
    .sub-head-txt {
      line-height: 17px;
    }
  }
}

/* Matching Content */

/* Dog Image Slider  */
// .dog-slider {
//   .image-slider {
//     cursor: pointer;

//     // min-width: 130px;
//     // height: 139.69px;
//     // border-radius: 20px;
//     // background: #ffffff;
//     // margin: 20px 17px 20px 0;

//     .dog-slider-img {
//       width: 130px;
//       height: 140px;
//       background-color: $color-white;
//       border-radius: 10px;
//       margin: 20px 17px 20px 0;
//       object-fit: cover;
//     }
//   }
// }

.dog-slider {
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;

  .image-slider {
    flex: 0 0 auto;
    margin: 20px 17px 20px 0;

    .dog-slider-img {
      width: 130px;
      height: 140px;
      background-color: $color-white;
      border-radius: 10px;
      // margin: 20px 17px 20px 0;
      object-fit: cover;
    }
  }
}
/* Dog Image Slider  */

/* No Data Found */
.matching-no-data-found {
  .dummy-img {
    justify-content: center;
    padding-top: 101px;
    .no-data-dummy-img {
      opacity: 0.5;
      // max-width: 238.38px;
      // width: 100%;
      // height: 269.31px;
    }
  }
  .header-text {
    max-width: 220px;
    margin: auto;
  }
}
/* No Data Found */

/* Match To Send */
.match-to-send {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 345px;
  height: 411px;
  background-color: $color-white;
  border-radius: 10px;
  .img-sec {
    // display: flex;
    // justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .animation-video {
      // max-width: 345px;
      width: 100%;
      height: 322px;
      height: 100%;
      object-fit: fill;
      border-radius: 10px;
    }
  }
}
/* Match To Send */
.reject-close-icon {
  // position: fixed;
  position: absolute;
  bottom: 100px;
  background: $color-white;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  box-shadow: 0px 0px 3px $color-black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
