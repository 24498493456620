@import 'src/assets/scss/variable.scss';


.directory-map-sec{
  position: relative;
  overflow: hidden;
  .category-wrap {
    position: absolute;
    z-index: 1;
    overflow: scroll;
    height: 55vh;
    .cat-inner {
      display: flex;
      cursor: pointer;
      background: #fff;
      margin: 3px 0 3px 10px;
      text-decoration: none;
      border-radius: 15px;
      padding: 4px 10px;
    }
    .active-cat{
      background-color: #F7D5CB;
    }
    .icon {
      height: 15px;
      width: 10px;
      margin: 5px 5px 0 0;
    }
  
  }
  .benefit-btn{
    position:absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
  .benefit-button.pink-button{
    // background-color: #F54D71;
    position:absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    line-height: 22px;
    font-family: $regulerFont;
    font-weight: 400;
    text-transform: none;
    text-transform: none;
    margin-bottom: 0 !important;
    width: 110px;
    box-shadow: 0px 3px 6px #00000029;
    svg{
      width: 18px;
      height: 18px;
    }
  }
  .benefit-selected-btn{
    background-color: #F7D5CB !important;
    // color: #ffffff !important;
    svg{
      path{
        fill: black;
      }
      path:last-child{
        fill: #FBC02D
      }
      g{
        g:last-child{
          stroke: black;
        }
      }
      
    }
  }
  .map-place-details{
    position: absolute;
    bottom: 30px;
    z-index: 999;
    .map-place-details-sec{
      width: 90%;
      margin: 0 auto;
      background-color: #ffffff;
      padding: 24px 18px;
      border-radius: 10px;
    }
    .map-places{
    
      display: flex;
      
    }
    .place-details-left{
      width: 36px;
      margin-right: 9px;
      .place-image{
        width: 36px;
        height: 36px;
      }
    }
  
    .place-details-right{
      width: 100%;
      .rating-sec{
        svg{
          color: #F54D71;
        }
      }
      .place-name{
        font-size: 18px;
        line-height: 24px;
        font-family: $regulerFont;
        font-weight: 600;
        color: $color-black;
      }
      .sponsored-name{
        font-size: 13px;
        line-height: 18px;
        font-family: $regulerFont;
        font-weight: 400;
        color: $color-black;
      }
      .visit-link{
        color:#F54D71;
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset:2px;
      }
      .f-bold{
        font-weight: 600;
      }
      .color-green{
        color: #59D09D;
      }
      .rating-details{
        display: flex;
      }
      .place-address{
        font-size: 16px;
        line-height: 22px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #707070;
      }
      .place-status{
        font-size: 12px;
        line-height: 16px;
        font-family: $regulerFont;
        font-weight: 400;
      }
     
     
    }
    .fav-bene-button{
      // background-color: #F54D71;
      background-color: #ffffff;
      color: #000000;
      font-size: 16px;
      line-height: 22px;
      font-family: $regulerFont;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 0;
      margin-top: 31px;
      max-width: 120px;
      // box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 3px 6px #00000029;
      svg{
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    .pink-button{
      background-color:#F54D71;
      color: #ffffff;
    }
    .fav-benefit-button-sec{
      display: flex;
      justify-content: space-around;
    }
    .fav-benefit-button-sec.fav-button-sec{
      justify-content: center !important;
    }
    .mr-9{
      margin-right: 9px;
    }
  }
  .benefit-map-btn {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 200px;
  }
}
