@import 'src/assets/scss/variable.scss';

// APP TOPBAR
.app-bar {
  z-index: 11111 !important;
  background: transparent !important;
  .MuiToolbar-gutters {
    color: $color-black;
    min-height: 60px !important;
    padding: 15px;
    width: 100%;
    justify-content: space-between;
    // max-width: 414px;
    // margin: auto;
  }
}
.profile-app-bar {
  .MuiToolbar-gutters {
    padding: 0 15px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
    z-index: 999;
  }
}
// REMOVE VERTICAL SCROLL
// ::-webkit-scrollbar {
//   display: none;
// }
.mr-10 {
  margin-right: 130px !important;
}

/* Width */
::-webkit-scrollbar {
  width: 0px;
  z-index: 1;
}
/* Track */
::-webkit-scrollbar-track {
  background: $color-gray;
  border-radius: 1px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-pink;
  border-radius: 1px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-pink;
}
.main-layout-wrapper {
  background: #fafafa;
  max-width: 414px;
  margin: auto;
  // height: '100vh';
  width: 100%;
  height: $_height;
  position: relative;
  overflow: hidden;
  @media (max-width: 425px) {
    height: $_height_mobile;
    max-width: 100%;
  }
}

// .confirm-password {
//   height: 69.5vh !important;
//   @media (max-width: 425px) {
//     height: calc(100vh - 60px) !important;
//   }
//   @media (max-height: 1024px) {
//     // height: 67.5vh !important;
//     height: calc(100vh - 142px) !important;
//   }
// }

.layout-wrapper {
  // display: flex;
  // flex: 1 1 auto;
  // overflow: hidden;
  // height: calc(100vh - 60px);
  height: 69.5vh;
  // height: 67vh;
  // height: calc(100vh - 34vh);
  position: inherit;
  display: block;
  margin: 0 38px;
  // margin: 0 15px;

  // width: 100%;
  // max-width: 414px;
  // margin: 60px auto 0px auto;
  // position: relative;
  // z-index: 1;
  overflow: auto;

  @media (max-height: 1024px) {
    height: 67.5vh;
  }

  @media (max-width: 425px) {
    height: calc(100vh - 60px);
  }
  @media (max-width: 375px) {
    margin: 0 20px;
  }
}
.layout-without-header {
  height: 100%;
  overflow: hidden;
  margin: 0;
}
.profile-layout-wrapper {
  margin: 0 15px;
  overflow: auto;
}
// LOGIN IMG
.auth-login-img {
  background-image: url('../assets//images/login1.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-signup-img {
  background-image: url('../assets//images/login2.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-password-img {
  background-image: url('../assets//images/login3.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.body-image {
  background-image: url('../assets//images/bg-img.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.chat-topbar-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  .call-icon {
    svg {
      width: 21px;
      height: 21px;
    }
  }
}
.delete-head-sec {
  padding: 0px !important;
  width: 262px !important;
  .favourites-delete-sec {
    border-bottom: 1px solid #a5a5a7;
    padding: 0px 16px 0px 16px;
    .dailog-head-text {
      font-size: 17px;
      line-height: 22px;
      font-family: $regulerFont;
      text-align: center;
      padding-top: 15px;
      font-weight: 600;
    }
  }
  .permission-btns {
    display: flex;
    .cancel-btn {
      border-right: 1px solid #a5a5a7;
      .favourites-cancel-btn {
        color: #54b5cd;
      }
    }
    .favourites-yes-btn {
      color: #f54d71;
    }
    .favourites-btn {
      font-size: 17px;
      line-height: 22px;
      font-family: $regulerFont;
      text-transform: capitalize;
      margin: 10px 0px 11px 0px;
    }
  }
  .MuiDialogContent-root {
    background-color: #d0d0d0;
    padding: 0px;
  }
  .MuiDialog-paper {
    border-radius: 15px;
  }
}
