@import 'src/assets/scss/variable.scss';

.terms-and-condition {
  .header-txt {
    font-size: 20px;
    line-height: 27px;
    font-family: $regulerFont;
    font-weight: 600;
    padding-top: 11px;
  }

  .sub-header-terms-pages {
    font-size: 18px;
    line-height: 17px;
    font-family: $regulerFont;
  }

  .page-desc {
    line-height: 22px;
    font-weight: 400;
    font-size: 18px;
    font-family: $regulerFont;
  }

  .website-connect-sec {
    .connect-with-website {
      line-height: 30px;
      font-weight: 700;
      font-size: 30px;
      font-family: $regulerFont;
    }

    .website-link {
      line-height: 24px;
      font-weight: 700;
      font-size: 18px;
      font-family: $regulerFont;
    }
  }
}

.preview {
  .dog-paw-icon {
    display: none;
  }
}

/* Account Details */
.account-details-sec {
  .edit-upgrade-btns {
    .custom-button {
      text-transform: capitalize;
      max-width: 164px;
      max-height: 56px;
      margin-bottom: 0px;
      position: relative;
      left: 4px;
      bottom: -2px;
    }
  }

  .header-txt {
    font-size: 20px;
    line-height: 27px;
    font-family: $regulerFont;
    font-weight: 600;
  }

  .sub-header {
    font-size: 18px;
    line-height: 17px;
    font-family: $regulerFont;
    font-weight: 400;
  }

  .pause-box-sec {
    overflow: scroll;
    overflow-y: scroll;
    height: 90vh;

    .page-content-sec {
      box-shadow: 0px 3px 6px #00000029;
      max-width: 375px;
      margin: -14px 0px 25px 3px;
      border: 1px solid white;
      border-radius: 10px;
      padding: 29px 10px 0px 10px;
      height: fit-content;

      .pause-head-sec {
        padding: 10px;

        .pause-profile-sec {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #c3c3c3;
          padding-top: 15px;

          .pause-profile-head {
            font-size: 13px;
            line-height: 18px;
            font-family: $regulerFont;
            font-weight: 700;
          }
        }

        .pause-profile-desc {
          font-size: 13px;
          line-height: 18px;
          font-family: $regulerFont;
          font-weight: 400;
        }
      }

      .link-head-sec {
        .link-profile-sec {
          display: flex;
          justify-content: space-between;

          .link-profile-head {
            font-size: 13px;
            line-height: 18px;
            font-family: $regulerFont;
            font-weight: 700;
          }

          .find-btn {
            width: 60px;
            height: 25px;
            margin-bottom: 10px;
            text-transform: capitalize;
            font-size: 11px;
            line-height: 17px;
            font-family: $regulerFont;
            font-weight: 600;
          }
        }

        .link-profile-desc {
          font-size: 13px;
          line-height: 18px;
          font-family: $regulerFont;
          font-weight: 400;
        }
      }

      .select-helper-txt {
        font-size: 13px;
        line-height: 18px;
        font-family: $regulerFont;
        font-weight: 600;
        color: #000000;
      }

      .push-notification-sec {
        display: flex;
        justify-content: space-between;

        .push-notification-txt {
          font-size: 13px;
          line-height: 18px;
          font-family: $regulerFont;
          font-weight: 400;
          color: #000000;
        }
      }

      .account-type-sec {
        display: flex;
        justify-content: space-between;

        .account-type-txt {
          font-size: 13px;
          line-height: 18px;
          font-family: $regulerFont;
          font-weight: 400;
          color: #000000;
        }

        .change-btn {
          width: 70px;
          height: 22px;
          font-size: 10px;
          line-height: 17px;
          font-family: $regulerFont;
          font-weight: 600;
          text-transform: capitalize;
        }
      }

      .tabs-sec {
        justify-content: space-between;
        border-bottom: 1px solid #c3c3c3;
        padding-top: 8px;
        padding-bottom: 4px;

        .terms-condition-tabs {
          font-size: 14px;
          line-height: 19px;
          font-family: $regulerFont;
          font-weight: 400;
          color: #000000;
        }
      }

      .btns-sec {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 42px;

        .log-out-btn {
          width: 134px;
          height: 36px;
          margin-bottom: 15px;
        }

        .delete-account-btn {
          width: 182px;
          height: 36px;
        }

        .sub-account-btn {
          max-width: 300px;
          height: 36px;
        }

        button {
          @media (max-width: 428px) {
            font-size: 13px;
          }
        }
      }

      .terms-condition-tab-sec {
        cursor: pointer;
      }
    }
  }
}

.terms-condition-tab-sec:active {
  background-color: #f0f0f0;
}

/* Account Details */

/* Download Data */
.download-data-sec {
  .header-txt {
    font-size: 20px;
    line-height: 27px;
    font-family: $regulerFont;
    font-weight: 600;
    padding-top: 11px;
  }

  .sub-header {
    font-size: 18px;
    line-height: 17px;
    font-family: $regulerFont;
    font-weight: 700;
  }

  .download-data-btn {
    text-transform: capitalize;
  }

  .country-select-dropdown {
    .country-dropdown {
      .MuiSelect-icon {
        color: #000000;
        font-size: 30px;
      }
    }
  }
}

.country-select-options {
  border-bottom: 1px solid #c3c3c3 !important;
  padding: 5px 0px 5px 0px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  font-family: $regulerFont !important;
  font-weight: 400 !important;
  color: #000000 !important;
}

/* Download Data */

.all-wrap {
  .Mui-disabled {
    -webkit-text-fill-color: $color-black !important;
    cursor: pointer;

    &::before {
      border-bottom: 1px solid $color-black !important;
    }
  }

  .MuiInput-input {
    font-weight: 600 !important;
  }

  .MuiSelect-select {
    font-weight: 600 !important;
  }
}

.menu-box {
  width: 100%;
}

.text-bold {
  font-weight: 600 !important;
}

.dailog-box {
  .dialog-wrap {
    width: 250px;

    .cancel-btn {
      color: $color-sky;
      margin: 0;
      padding: 0 28px;
      border-right: 1px solid gray;
      border-radius: 0;
      text-transform: capitalize;
    }

    .yes-btn {
      color: $color-pink;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  .MuiDialogContent-root {
    padding: 20px 24px 0 20px;
  }

  .custom-btn {
    .button-text {
      font-size: 12px;
    }
  }

  .delete-text {
    border-bottom: 1px solid gray;
    margin: 0 -21px;
  }

  .delete-padding {
    padding: 0 12px 12px;
  }
}

.mr-4 {
  margin-right: 5px !important;
}

.custom-select.mr-0 {
  .MuiSelect-icon {
    margin-right: 0;
  }
}

.edit-icon {
  width: 14px;
  height: 14px;
}

.add-icon {
  width: 20px;
  height: 20px;
}

.email-mobile-field {
  input {
    opacity: 0.7 !important;
  }
}
.react-select {
  height: max-content !important;
  .select__control {
    border: 0;
    background-color: #fafafa;
  }
  .select__indicators {
    display: flex;
    .select__indicator {
      display: none;
    }
    .select__indicator-separator {
      display: none;
    }
    svg {
      cursor: pointer;
    }
  }
  .select__multi-value {
    background-color: #f6d6c9;
    border-radius: 11px;
  }
  .select__multi-value__remove {
    svg {
      fill: white;
      background: black;
      border-radius: 50%;
    }
  }
  .select__menu-protal {
    border: 0 !important;
  }
  .select__control {
    border: 0;
    box-shadow: none !important;
    border-bottom: 1px solid black;
    min-height: 0;
    border-radius: 0;
  }
  .select__control:hover {
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
  }
  .select__value-container {
    padding: 0;
  }
}
