@import 'src/assets/scss/variable.scss';

.manage-account {
    height: 100%;

    .text-underline {
        text-decoration: underline;
        cursor: pointer;
    }

    .heading {
        padding-top: 11px;
        padding-bottom: 27px;
    }

    .manage-account-conntet {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .email-text {
        padding-bottom: 27px;
    }

    .manage-account-edit-page {
        height: 100%;

        .form-content {
            height: calc(100% - 62px);
        }

        .manage-account-button {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                width: max-content;
            }

            .save-button {
                padding: 8px 46px;
            }

            .delete-email-button {
                padding: 8px 29px;
            }

            .disable-button {
                opacity: 0.7;
                background-color: #59D09D !important;
                color: #ffffff !important;
            }
        }
    }

    .MuiInputBase-root.Mui-focused::before {
        border-bottom: 1.5px solid #f54d71 !important;
    }

    .MuiInputBase-root.Mui-focused::after {
        border-bottom: 1.5px solid #f54d71 !important;
    }

    .focused-filed {
        .MuiInputBase-root::after {
            border-bottom: 1.5px solid #f54d71 !important;
        }

        .MuiInputBase-root::before {
            border-bottom: 1.5px solid #f54d71 !important;
        }
    }

    .focused-select {
        .MuiSelect-select {
            border-bottom: 1.5px solid #f54d71 !important;
        }
    }


    .gap-0 {
        gap: 0;
    }
}