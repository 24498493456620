@import 'src/assets/scss/variable.scss';

.upload-options {
  position: absolute;
  z-index: 111;

  width: 100%;
  bottom: 22px;
  padding: 0px 8px;
  // background-color: $color-white;
  .MuiList-padding {
    padding: 0;
    background: $color-light-gray;
    border-radius: 15px;
  }
  .MuiListItem-root {
    color: $color-gray44;
    border-bottom: $border-bottom;
    padding: 16px 18px;
    svg {
      color: $color-pink;
    }
    span,
    p {
      font-size: 20px;
      font-family: $regulerFont;
      cursor: pointer;
    }
  }
  .cancel-btn {
    width: 100%;
    background: #ffff;
    color: $color-pink;
    padding: 16px;
    font-family: $regulerFont;
    text-transform: unset;
    font-size: 20px;
    border-radius: 12px;
    margin-top: 8px;
    height: 56px;
    &:hover {
      background: #ffff;
    }
    .MuiListItem-root {
      color: $color-gray44;
      border-bottom: $border-bottom;
      padding: 16px 18px;
      svg {
        color: $color-pink;
      }
      span,
      p {
        font-size: 20px;
        font-family: $regulerFont;
        cursor: pointer;
      }
    }
  }
}

.image-wrapper {
  img {
    width: 100%;
    max-height: 93px;
    object-fit: contain;
    border-radius: 10px;
  }
}
.camera-wrap {
  position: fixed;
  max-width: 414px;
  height: $_height;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 12.5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1111;
  @media (max-width: 425px) {
    height: $_height_mobile;
    top: 0;
  }
  video {
    width: 98%;
  }
}
.camera-wrap {
  .capture-wrap {
    margin-top: 10px;
  }
}
.camera-wrap.clicked {
  .capture-wrap {
    margin-top: 0px;
    display: initial !important;
    img {
      width: 100%;
      height: auto;
    }
  }
  .retake-wrap {
    color: #fff;
    display: flex;
    justify-content: center;
    span {
      padding: 20px;
      width: 30%;
      text-align: center;
      cursor: pointer;
    }
  }
}
.crop-dialog-box {
  .MuiPaper-root {
    max-height: calc(100vh - 25%) !important;
    max-width: 600px;
    margin: 0;
    // overflow-y: hidden;
    .dialog_content {
      width: 414px;
    }
  }
}
