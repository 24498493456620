.progress-bar {
  height: 2px;
  background-color: transparent;
  display: flex;
  max-width: 232px;
  margin: auto;
  .dash {
    flex: 1;
    height: 100%;
    border-radius: 5px;
  }
  .dash:not(:last-child) {
    margin-right: 8px;
  }
  .active-dash {
    background-color: #f54d71;
  }

  .inactive-dash {
    background-color: #c3c3c3;
  }
}
