@import '../../../../assets/scss/variable.scss';

.kennel-tab-sec {
  // height: 100%;
  // max-width: 378px;
  .MuiTabs-indicator {
    display: none;
  }

  .kennel-tab {
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 0px;
    min-width: 160px;
    min-height: 36px;
    box-shadow: 0px 0px 5px 1px #d9d9d9;
    margin-right: 2px;
    margin-left: 3px;
    color: #000000;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 20px;
    font-family: $regulerFont;
    font-weight: 400;
    z-index: 1;
    opacity: 1;
    @media only screen and (max-width: 890px) {
      min-width: 170px;
    }
    @media only screen and (max-width: 425px) {
      min-width: 165px;
    }
    @media only screen and (max-width: 400px) {
      min-width: 148px;
    }
    @media only screen and (max-width: 370px) {
      font-size: 12px;
      line-height: 15px;
      min-width: 140px;
    }
    @media only screen and (max-width: 335px) {
      min-width: 130px;
    }
  }

  .kennel-tab-active {
    color: #fafafa !important;
    background-color: #f54d71 !important;
    opacity: 1 !important;
  }

  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: auto auto;
    row-gap: 16px;
    // max-width: 378px;
    // width: calc(100vh - 84%);
    // column-gap: 25px;
    @media only screen and (max-width: 480px) {
      // width: 94%;
    }
    @media only screen and (max-width: 400px) {
      // column-gap: 0px;
      // width: 95%;
    }
    @media only screen and (max-width: 390px) {
      // width: 93%;
    }
    @media only screen and (max-width: 379px) {
      // width: 88%;
    }
    @media only screen and (max-width: 325px) {
      // width: 79%;
    }
  }

  .MuiTabs-scroller {
    // max-width: 378px;
    overflow: inherit !important;
  }

  .kennel-list-sec {
    overflow: inherit;
    padding-top: 7px;
  }

  .kennel-content-sec {
    padding: 0px;
    // height: calc(100% - (115px + 84px));
  }

  .kennel-list-sec:last-child {
    padding-bottom: 24px;
  }
}

.star-bone {
  right: 0 !important;
}

.collect-bones {
  height: max-content;
  padding-bottom: 0px;
  @media only screen and (max-width: 925px) {
    padding-bottom: 0px;
  }

  @media only screen and (max-width: 425px) {
    padding-bottom: 67px;
  }
  .dog-paw-icon {
    display: none;
  }
}

.collect-tab {
  .blur {
    filter: blur(10px) !important;
  }
}

.kennel-tab-box {
  position: sticky;
  top: 0px;
  background: #fafafa;
  z-index: 9;
  // width: 385px;
  // height: 115px;
}

.collect-sec {
  .blur {
    filter: blur(10px) !important;
  }
}

.mt-20 {
  margin-top: 127px;
}

.icon-wrap-msg {
  margin-right: 7px;
}

.msg-img-sec {
  // padding: 7px 0px 6px 17px;
  width: 30%;
}

.msg-img {
  min-width: 0px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  object-fit: cover;
  border-radius: 50%;
}

.delete-head-sec {
  padding: 0px !important;
  width: 262px !important;

  .favourites-delete-sec {
    border-bottom: 1px solid #a5a5a7;
    padding: 0px 16px 0px 16px;

    .dailog-head-text {
      font-size: 17px;
      line-height: 22px;
      font-family: $regulerFont;
      text-align: center;
      padding-top: 15px;
      font-weight: 600;
    }
  }

  .permission-btns {
    display: flex;

    .cancel-btn {
      border-right: 1px solid #a5a5a7;

      .favourites-cancel-btn {
        color: #54b5cd;
      }
    }

    .favourites-yes-btn {
      color: #f54d71;
    }

    .favourites-btn {
      font-size: 17px;
      line-height: 22px;
      font-family: $regulerFont;
      text-transform: capitalize;
      margin: 10px 0px 11px 0px;
    }
  }

  .MuiDialogContent-root {
    background-color: #d0d0d0;
    padding: 0px;
  }

  .MuiDialog-paper {
    border-radius: 15px;
  }
}

.msg-box {
  .MuiBox-root {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.drop-down-sec-thrown {
  position: absolute;
  top: 13px;
  right: -16px;
  margin: 0;
  cursor: pointer;

  button {
    min-width: 39px;
  }
}

.font-bold {
  font-weight: bold !important;
}

.badge-sec {
  position: relative;

  .badge {
    position: absolute;
    top: -18%;
    left: 86%;
    height: 18px;
    width: 100%;
    max-width: 19px;
    border-radius: 50%;
    padding: 4px 8px;
    background-color: #f54d71;
    color: #ffffff;
    font-size: 9px;
    line-height: 11px;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 2;
    @media only screen and (max-width: 410px) {
      left: 88%;
    }
    @media only screen and (max-width: 379px) {
      left: 84%;
    }
    @media only screen and (max-width: 370px) {
      left: 82%;
    }
    @media only screen and (max-width: 350px) {
      width: 67%;
    }
  }

  .hide {
    display: none;
  }
}

.dog-details-sec {
  cursor: pointer;
  overflow: auto !important;
  white-space: nowrap;
  // cursor: grab;
}

/* Thrown Bones */
.bones-card-sec {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 378px;

  &:hover {
    background: rgb(221 218 218) !important;
    @media (max-width: 425px) {
      background: transparent !important;
    }
  }

  ::-webkit-scrollbar {
    height: 3px !important;
    width: 3px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 5px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $color-gray !important;
    border-radius: 20px !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $color-gray !important;
    border-radius: 20px !important;
  }

  // margin-left: 3px;
  position: relative;

  .bones-box-sec {
    background-color: transparent;
    padding: 25px 0px 0px 17px;

    .dog-name {
      font-weight: 700 !important;
    }

    .dog-img {
      border-radius: 15px;
      max-width: 60px;
      width: 100%;
      object-fit: cover;
      height: 60px;
    }

    .bone-sec {
      padding-top: 6px;
      margin-left: 18px;
      border-bottom: 1px solid #c3c3c3;
      width: 65%;

      // overflow-x: scroll;
      // white-space: nowrap;
      .bones-grid-sec {
        .dog-details {
          border-right: 2px solid #000000;
          // height: 20px;
          padding-left: 6.6px;
          padding-right: 8.4px;
        }

        .dog-details:first-child {
          padding-left: 0px;
        }

        .dog-details:last-child {
          border-right: none;
          padding-right: 0px;
        }

        .detail-content {
          padding-right: 10px;
          max-width: 25.13px;
          // height: 18.6px;
          align-self: center;
        }

        .location {
          align-self: center;
        }
      }
    }
  }

  .bones-box-sec:last-child .bone-sec {
    border-bottom: none;
  }

  .bones-star-icon {
    background-color: transparent;
    cursor: pointer;
    margin: 10px 17px 0px 0px;
  }
}

.icon-wrap {
  position: absolute;
  right: 19px;
  top: 8px;
  min-width: 0;
  margin: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  background: #fff !important;
  border-radius: 6px;
  padding-left: 17px;
}

.bone-icon-sec {
  opacity: 1 !important;

  .bone-icon {
    position: absolute;
    right: 19px;
    top: 8px;
    min-width: 0;
    margin: 0px;
    background-color: transparent;
  }

  .drop-down-sec {
    position: absolute;
    top: 0px;
    right: 33px;

    .more-btn {
      position: relative;
      padding: 0;
      min-width: 20px;
    }

    .drop-down-btn {
      min-width: 0px;
    }
  }

  .respond-card-dialog {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    font-size: 50px;
  }
}

/* Thrown Bones */

/* Message */
.msg-head-sec {
  box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  height: 100%;

  .msg-content-sec {
    cursor: pointer;
    /* display: grid; */
    /* grid-template-columns: auto auto; */
    /* padding: 7px 0 6px 17px; */
    display: flex;
    /* justify-items: center; */
    text-align: center;
    padding: 7px 0 7px 0px;
    cursor: pointer;

    .user-img-sec {
      padding: 16px 0px 0px 17px;

      .top-margin {
        margin-top: 4px;
      }

      .user-img {
        min-width: 0px;
        width: 50px;
        height: 50px;
        background-color: transparent;

        svg {
          width: 50px;
          height: 50px;
        }
      }
    }

    .msg-sec {
      padding: 0px 0px 0px 0px;
      width: 244px;

      .msg-content {
        border-bottom: 1px solid #c3c3c3;

        .user-name {
          font-weight: 700;
          line-height: 17px;
          padding-top: 10px;
        }

        .user-msg {
          line-height: 20px;
        }

        // .top-padding {
        //   // padding-top: 25px;
        // }
      }

      .your-turn-btn {
        font-size: 9px;
        line-height: 17px;
        color: #ffffff;
        font-family: $regulerFont;
        background-color: #e0e0e0;
        max-width: 55px;
        width: 100%;
        height: 17px;
        padding: 4px;
        margin: 0px;
        align-self: center;
      }
    }
  }

  .msg-content-sec:last-child .msg-content {
    border-bottom: none;
  }
}

/* Message */

/* Collected Bones */

.collected-bones-tab {
  .header-text {
    line-height: 17px;
  }

  .content-text {
    line-height: 20px;
  }
}

// .tabLabel{
//   position: relative;
//   display: flex;
//   align-items: center;
// }

/* Collected Bones */

.delete-dialog-box {
  .MuiDialogContent-root {
    background-color: #fff;
    padding: 0px;
    position: relative;
  }

  .MuiDialog-paper {
    border-radius: 15px;
  }

  .close-icon-report {
    position: absolute;
    right: 8px;
  }
}

/* Matches Tab */
.drop-down-sec {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin: 17px -23px 0px 0px;

  .drop-down-btn {
    padding: 0px;

    .MuiSvgIcon-fontSizeMedium {
      font-size: 27px;
    }
  }
}

.drop-down-menu {
  .MuiPopover-paper {
    left: 890px !important;
    border-radius: 13px;
  }

  .menu-item {
    border-bottom: 1px solid #c3c3c3;
  }

  .menu-item:last-child {
    border-bottom: none;
  }
}

.matches-star-icons {
  position: absolute !important;
  right: 36px;
  top: 8px;
  background: transparent !important;
}

.star-sec {
  position: absolute;
  right: 20px !important;
  top: 0;
}
.report-box {
  .MuiMenu-paper {
    // position: static;
    border-radius: 15px;
    max-width: 111px;
    width: 100%;
    top: 41% !important;
    // left: 540px !important;
    @media only screen and (min-width: 1900px) {
      top: 25% !important;
    }
    @media only screen and (max-width: 925px) {
      top: 44% !important;
    }
    @media only screen and (max-width: 425px) {
      top: 32% !important;
    }
  }
}

/* Matches Tab */

/* No Data Found */

.kennel-page-head-sec {
  padding-top: 50px;

  .kennel-dummy-img-sec {
    .dummy-img {
      max-width: 252.21px;
      width: 100%;
      height: 264.15px;
    }

    .thrown-bones-img {
      max-width: 260.27px;
      width: 100%;
      height: 271.15px;
    }

    .message-img {
      max-width: 225.61px;
      width: 100%;
      height: 281.15px;
    }

    .collect-bone-img {
      max-width: 225.68px;
      width: 100%;
      height: 269.15px;
    }
  }
}

/* No Data Found */

/* Respond Card */
.dailog-respond {
  // max-width: 314px;
  width: 100%;
}
// .collect-bones-wrap {
// height: calc(100vh - 30vh);
// height: calc(100% - 26%);
// height: calc(100vh - 30vh);
// }
.respond-card-sec {
  // max-height: 562px;
  @media only screen and (min-width: 1900px) {
    height: 100%;
  }
  height: 460px;
  .respond-card-head {
    .head-text {
      font-size: 22px;
      line-height: 30px;
      font-family: $regulerFont;
      font-weight: 400;
    }

    .dog-name {
      font-size: 22px;
      line-height: 30px;
      font-family: $regulerFont;
      font-weight: 700;
    }

    .accepted-name {
      font-size: 22px;
      line-height: 30px;
      font-family: $regulerFont;
      font-weight: 700;
      color: #f54d71;
    }
  }

  .dog-img-card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    // height: 1  00%;
    // width: 100%;
    .respond-card-grid {
      @media only screen and (max-width: 379px) {
        flex-wrap: nowrap;
        column-gap: 20px;
      }
      @media only screen and (max-width: 410px) {
        flex-basis: 87%;
        max-width: 100%;
      }
    }

    img {
      max-width: 100px;
      width: 100%;
      height: 100%;
      max-height: 100px;
    }

    .dog-img {
      margin-top: 0px;
      border: 1px solid white;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #00000029;

      object-fit: fill;
      // max-width: 100px;
    }
  }

  .content-sec {
    border-top: 1px solid #c3c3c3;
    border-bottom: 1px solid #c3c3c3;
    padding-top: 20.9px;
    padding-bottom: 20.3px;

    .content-text {
      font-size: 22px;
      line-height: 30px;
      font-family: $regulerFont;
    }
  }

  .trainer-permission-content {
    padding-top: 21.7px;

    .dog-trainer-name {
      font-size: 22px;
      line-height: 30px;
      font-family: $regulerFont;
      font-weight: 700;
    }
  }

  .button-sec {
    .close-btn {
      background-color: transparent;
      max-width: 50px;
      width: 100%;
      height: 50px;
    }

    .agree-btn {
      background-color: transparent;
      max-width: 50px;
      width: 100%;
      height: 50px;
    }
  }

  .chat-sec {
    padding-top: 24px;
    padding-bottom: 28px;

    .chat-icon {
      max-width: 50px;
      width: 100%;
      height: 50px;
    }

    .chat-text {
      align-self: center;
    }
  }
}

.icon-sec {
  width: 254px;
  margin: 0 auto;
}

/* Respond Card */

/* Card Swipe */
.swipe-box-sec {
  border-radius: 4%;
  background-color: $color-pink;
}

.swipe-sec {
  position: absolute;
  right: -20%;
  top: 40%;
}

.swipe-container {
  max-width: 30px;
  width: 100%;
  height: 29px;
}

.swiped {
  cursor: grabbing;
}

.women-icon {
  padding-left: 50px;
}

.dog-icon {
  margin-top: 100px;
}

.swipe {
  cursor: default;
}

#demo-positioned-menu {
  // .MuiPaper-root {
  // top: 315px !important;
  // }
  ul {
    margin: 10px !important;
    padding: 0px;

    li {
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      font-family: $regulerFont;
      color: $color-black;
      padding: 6px;
      width: fit-content;
      @media only screen and (max-width: 425px) {
        min-height: 0;
      }
    }
  }
}

.rewind-list {
  .MuiList-root {
    padding: 0 !important;
  }
}

.box-sec {
  border-radius: 4%;
  background-color: $color-white !important;
}

.rejectpopup {
  .MuiDialogContent-root {
    width: 385px;
  }
}
