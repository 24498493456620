@import 'src/assets/scss/variable.scss';

.media-dialog-box {
  background-color: transparent;
  .MuiPaper-root {
    margin: 0;
    width: 100%;
    max-width: 409px;
    height: $_height;
    @media (max-width: 425px) {
      height: $_height_mobile;
      max-width: 100% !important;
    }
  }
  .close {
    position: absolute;
    left: 3%;
    top: 2%;
    z-index: 999;
    width: 24px;
    height: 24px;
  }
}

.media-sub-content {
  position: relative;
  background-color: $color-black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
}

.media-modal {
  position: relative;
  width: 100%;
  height: 100%;
}

.media-modal-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
