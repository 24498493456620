@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Fonts
$regulerFont: 'Open Sans', sans-serif;
@font-face {
  font-family: RedHatRegular;
  src: url('../fonts/RedHatDisplay-Regular.woff');
}
@font-face {
  font-family: SFProDisplay;
  src: url('../fonts/SFPRODISPLAYREGULAR.woff');
}

// Colors
$color-black: #000000;
$color-blue: #3b66c4;
$color-white: #ffffff;
$color-gray: #d4d6d8;
$color-red: #ff0000;
$color-pink: #f54d71;
$background-color: #fafafb;
$color-pink-accent: #f6d6c9;
$color-light-gray: #d0d0d0;
$border-bottom: 0.5px solid #7070702e;
$border-top: 0.5px solid #7070702e;
$color-gray44: #707070;
$border: #e0e0e0;
$color-gray-accent: #c3c3c3;
$color-sky: #54b5cd;
$_height: calc(100vh - 25%);
$_height_mobile: 100vh;
