@import '../../../../assets//scss/variable.scss';

.auth-wc-main-container {
  position: relative;
  height: 100%;
}
.login-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 23px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
}
.logo-content {
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  margin-top: 114px;
}
.login-banner-content {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
.social-icon {
  margin-left: 8px;
}
.wc-btn-content {
  color: $color-white;
  .or-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;
    .or-text {
      margin: 0 22px;
    }
    .or-line {
      border: 0.5px solid $color-white;
      width: 50%;
    }
  }
  .login-bottom-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 31px;
    margin-bottom: 25px;
  }
}
