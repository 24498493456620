@import '../../../../assets/scss/variable.scss';

.google-ads {
  position: fixed;
  bottom: 22%;
  width: 100%;
  max-width: 413px;
  margin: auto -15px;
  height: 65px !important;
  max-height: 65px !important;
  ins {
    height: 65px !important;
    max-height: 65px !important;
  }
}
.footer-tab {
  // position: absolute;
  position: fixed;
  // top: auto;
  bottom: 12%;
  box-shadow: 0px -1px 3px -1px rgba(0, 0, 0, 0.16);
  max-width: 413px;
  z-index: 1111;
  width: 100%;
  background-color: $color-white;
  // height: 90px;
  margin: auto -15px;
  // .border-wrapper {
  //   position: relative;
  // }
  // .border-wrapper::after {
  //   content: '';
  //   position: absolute;
  //   bottom: -80px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 135px;
  //   height: 5px;
  //   background-color: #000;
  //   margin-bottom: -8px;
  // }
  @media (max-width: 425px) {
    bottom: 0;
  }
  .bottom-footer-tab {
    .tab-sec {
      display: flex;
      padding: 5px 0px;
      .bottom-tab {
        // display: block;
        padding: 5px 0;
        flex-direction: column;
        .tab-icons {
          justify-content: center;
        }
        .tab-name {
          display: flex;
          justify-content: center;
          .MuiListItemText-primary {
            font-size: 11px;
            line-height: 15px;
            font-family: $regulerFont;
            font-weight: 400;
          }
        }
      }
    }
  }
  .noti-count-show {
    position: absolute;
    right: 26px;
    background: #f54d71;
    border-radius: 50%;
    font-size: 9px;
    color: #fff;
    padding: 3px;
    top: 3px;
    height: 16px;
    width: 16px;
    text-align: center;
  }
  .hide {
    display: none;
  }
}
