@import '../../../../assets/scss/variable.scss';

.help-center {
  .header-txt {
    font-size: 20px;
    line-height: 27px;
    font-family: $regulerFont;
    font-weight: 600;
  }
  .sub-header {
    font-size: 18px;
    line-height: 17px;
    font-family: $regulerFont;
    font-weight: 400;
  }
  .accordian-box-sec {
    // height: 84px;
    box-shadow: 0px 3px 6px #00000029;
    max-width: 375px;
    margin: -12px 3px 0px 3px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 29px 10px 0px 10px;
  }
  .help-center-upgrade-btn {
    .custom-button {
      text-transform: capitalize;
      // max-width: 164px;
      // max-height: 56px;
      margin-bottom: 0px;
      bottom: -2px;
    }
  }
  .content-head-sec {
    padding: 0px 15px 0px 15px;
    .content-sec {
      border-top: 1px solid #c3c3c3;
      .tab-sec {
        cursor: pointer;
        display: flex;
        padding: 15px 0px 15px 0px;

        .subject-title {
          font-size: 13px;
          line-height: 18px;
          font-family: $regulerFont;
          font-weight: 700;
        }

        .content-errow {
          align-self: center;
        }
      }
    }
  }
}

.tab-sec:active {
  background-color: #f0f0f0;
}

.MuiModal-backdrop {
  // left: 37% !important;
  // right: 37% !important;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  // width: 415px;
  // margin-left: 592px;
}

/* Content Page */
.content-page-sec {
  .header-txt {
    font-size: 20px;
    line-height: 27px;
    font-family: $regulerFont;
    font-weight: 600;
    padding-top: 11px;
  }
  .sub-header {
    font-size: 18px;
    line-height: 17px;
    font-family: $regulerFont;
    font-weight: 700;
  }
  .subject-desc {
    font-size: 18px;
    line-height: 22px;
    font-family: $regulerFont;
    font-weight: 400;
  }
}
/* Content Page */
