@import '../../../../assets/scss/variable.scss';

.premium-popup-sec {
  .premium-card {
    max-width: 346px;
    height: 100%;

    .premium-dog-img {
      display: flex;
      justify-content: center;
      padding-top: 40px;

      .dog-img {
        max-width: 125.81px;
      }
    }
    .premium-head-text {
      font-size: 17px;
      line-height: 23px;
      font-family: $regulerFont;
      text-align: center;
    }
    .card-action-btn {
      display: flex;
      justify-content: center;
      padding: 0px;
      .upgrade-premium-btn {
        background-color: #f54d71;
        color: #ffffff;
      }
      .premium-cancel-btn {
        background-color: transparent;
        border: 1px solid #f54d71;
        color: #f54d71;
      }
      .card-btns {
        max-width: 300px;
        height: 36px;
      }
    }
  }
}
.premium-popup{
  .MuiPaper-root { 
      max-height: calc(100vh - 37%) !important;
      border-radius: 10px;
      max-width: 317px;
      overflow-y: hidden;
    }
}

.dialog_box {
  .MuiPaper-root {
    max-height: calc(100vh - 30%);
  }
  .close {
    position: absolute;
    left: 90%;
    top: 2%;
    z-index: 999;
  }
  .call-screen-title {
    padding: 0px;
  }
  .call-screen-content {
    padding: 0px;
  }
  .MuiDialog-paper {
    .call-screen-close-icon {
      top: -8%;
    }
  }
}

.call-box {
  .MuiPaper-root {
    max-width: 412px;
    width: 100%;
    max-height: calc(100vh - 24%);
  }
}

.contact-card-box {
  .MuiPaper-root {
    max-width: 345px;
    width: 100%;
    height: 347px;
    border-radius: 10px;
  }
}
