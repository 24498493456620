@import 'src/assets/scss/variable.scss';

.subscription-plan-sec {
  .header-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    font-family: 'Open Sans', sans-serif;
    color: $color-pink;
  }
  .subscription-plan {
    box-shadow: 0px 3px 6px #00000029;
    margin: 0 3px;
    border-radius: 10px;
    padding: 28.5px 13px;
    margin-top: 23px;
    margin-bottom: 23px;
    .sub-details {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-gray-accent;
      padding-bottom: 9px;
      padding-top: 9px;
      .sub-header {
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        font-family: 'Open Sans', sans-serif;
      }
      .sub-text {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        font-family: 'Open Sans', sans-serif;
      }
    }
    .payment-method {
      border-bottom: 0;
      padding-bottom: 0;
    }
    // .card-image {
    // }

    .card-form {
      padding-top: 9.5px;
      padding-bottom: 20.5px;
      border-bottom: 1px solid $color-gray-accent;
      .text-field-text {
        font-size: 13px;
        line-height: 18px;
        font-family: $regulerFont;
        font-weight: 500;
        color: $color-black;
      }
      .text-field-text-gray {
        color: $color-gray-accent;
        margin-left: 4px;
      }
      .cvv-icon {
        margin-left: 6px;
        margin-top: 4px;
      }
    }
    .pb-6 {
      padding-bottom: 6px;
    }
    .subscription-btn-sec {
      padding-top: 42px;
      .custom-btn {
        width: auto;
        margin-bottom: 13px;
      }
      .cancel-btn {
        margin-bottom: 43.5px;
      }
    }
    .sub-details:last-child {
      border-bottom: 0;
    }
  }
}
