body {
  .custom-button {
    border-radius: 20px;
    font-size: 15px;
    max-height: 36px;
    // text-transform: inherit;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    font-family: Open Sans, sans-serif;
    &:nth-child(n) {
      margin-bottom: 16px;
    }
    .button-text {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
