@import '../../../../assets/scss/variable.scss';
.benefits-detail-sec {
  // overflow-y: hidden !important;
  .benefits-header-txt {
    font-size: 18px;
    font-family: $regulerFont;
    text-align: center;
    padding-top: 18px;
  }
  .benefits-box-sec {
    @media only screen and (max-width: 410px) {
      width: 99%;
    }
    cursor: pointer;
    :hover {
      background: #ebeef1;
    }
    .white-Petbarn-box {
      box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px 4px 1px 1px;
      max-width: 378px;
      margin-left: 3px;
      padding: 9px;
      .Petbarn-box-head {
        justify-content: space-between;
        .start-icon {
          max-width: 23.76px;
          height: 22.75px;
          background-color: transparent;
          align-self: center;
        }
        .benefit-image {
          max-width: 130px;
          width: 100%;
          object-fit: contain;
          height: 40px;
        }
        .paws-icon {
          max-width: 130px;
          height: 40px;
        }
      }
    }
    .Petbarn-box {
      box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 1px 1px 4px 4px;
      max-width: 378px;
      margin-left: 3px;
      background-color: #ebeef1;
      padding: 9px;
      justify-content: space-between;
      .store-off-content {
        .pet-store-txt {
          font-size: 15px;
          line-height: 17px;
          font-family: $regulerFont;
          font-weight: 400;
        }
        .pet-store-head-txt {
          font-size: 15px;
          line-height: 17px;
          font-family: $regulerFont;
          font-weight: 700;
        }
      }
      .next-arrow-icon {
        align-self: center;
        max-width: 9.52px;
        height: 16.65px;
        background-color: transparent;
      }
    }
  }
}

/* Benefits Details page */
.benefit-detail-page-sec {
  .benefit-detail-page {
    padding-top: 18px;
  }
  .description-text {
    font-size: 15px;
    line-height: 20px;
    font-family: $regulerFont;
  }
  .discount-text {
    color: #f54d71;
  }
  .font-r-12 {
    padding-right: 5px;
  }
  .web-link {
    color: $color-blue !important;
    text-decoration: underline !important;
  }
  .barcode-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    img {
      max-width: 345px;
      height: 84px;
    }
  }
}

.content-height {
  line-height: 12px;
}

/* Benefits Details page */

/* Blank Page */
.blank-page-benefit-sec {
  .header-text {
    padding-top: 30px;
  }
  .dummy-img-benefit-sec {
    padding-top: 52px;
    justify-content: center;
    padding-bottom: 44px;
    img {
      width: 225.68px;
      height: 269.15px;
    }
    .dummy-img {
      // width: 100%;
      // height: 100%;
       width: 225.68px;
      height: 269.15px;
    }
  }
}
/* Blank Page */

.benefit-search {
  // max-width: 378px;
  margin-left: 3px !important;
  width: 99%;
  box-shadow: -2px 0px 5px 1px #d9d9d9, 2px 0px 5px 1px #d9d9d9;
  border-radius: 3px;
  @media only screen and (max-width: 410px) {
    width: 98%;
  }
  .MuiInput-root:after {
    content: none !important;
    border-bottom: 0;
  }
  .MuiInput-root::before {
    content: none !important;
    border-bottom: 0;
  }
  ::placeholder {
    color: $color-black;
    opacity: 1;
    font-size: 15px;
  }
  .MuiInputBase-input {
    margin-left: 10px;
    background: #fff;
  }
}
