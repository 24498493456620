@import '../../../../assets/scss/variable.scss';

.favourites-sec {
  .s-bold {
    font-weight: 700;
  }
  .header-text {
    padding: 23px 23px 15px 30px;
  }
  .favourites-tab-box {
    // height: $_height;
    // height: calc(100vh - 417px);
    overflow: scroll;
    padding-top: 10px;
    padding-bottom: 80px;
    // @media (max-width: 425px) {
    //   height: calc(100vh - 25vh);
    // }
    // @media only screen and (max-width: 420px) {
    //   height: calc(100vh - 44vh);
    // }
    // @media only screen and (min-width: 1900px) {
    //   height: calc(100vh - 44vh);
    // }
  }
  .favourites-tab-sec {
    position: relative;
    // z-index: 999;
    .MuiTabs-indicator {
      display: none;
    }
    .favourites-tab {
      border: 1px solid transparent;
      border-radius: 20px;
      padding: 0px;
      min-width: 100px;
      min-height: 36px;
      box-shadow: 0px 0px 5px 1px #d9d9d9;
      margin-right: 2px;
      margin-top: 2px;
      margin-left: 3px;
      color: #000000;
      text-transform: capitalize;
      font-size: 15px;
      line-height: 20px;
      font-family: $regulerFont;
      font-weight: 400;
      @media only screen and (max-width: 359px) {
        min-width: 90px;
      }
    }
    .selected-tab {
      color: #fafafa;
      background-color: #f54d71;
    }
    .MuiTabs-flexContainer {
      justify-content: space-between;
    }
    .MuiTabs-scroller {
      overflow: inherit !important;
    }
    .tab-list-sec {
      overflow: inherit;
    }
    .tab-content-sec {
      padding: 0px;
    }
  }
}

.favourite-tab-content {
  padding-bottom: 30px;
  @media only screen and (max-width: 890px) {
    padding-bottom: 70px;
  }
  @media only screen and (max-width: 420px) {
    padding-bottom: 0px;
  }
}

/* Places And Benefits Tab */
.places-card-sec {
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 378px;
  margin-left: 3px;
  margin-right: 3px;
  &:hover {
    background: #ebeef1;
  }

  .card-head {
    justify-content: space-between;
    padding: 0px 4px 12px 4px;
    .card-icon {
      align-self: center;
      padding-top: 14px;
      padding-left: 15px;
    }
    .star-icon {
      background-color: transparent;
      cursor: pointer;
    }
    .delete-dialog-box {
      .MuiModal-backdrop {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .description-content {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1px 1px 4px 4px;
    max-width: 378px;
    // margin-left: 3px;
    background-color: #ebeef1;
    padding: 9px;
    justify-content: space-between;
    .card-store-txt {
      font-weight: 700;
    }
  }
}

.delete-dialog-box {
  .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
/* Places And Benefits Tab */
.Petbarn-icon {
  img {
    max-width: 113px;
    // height: 100%;
  }
}
.benefit-img {
  // max-width: 117px;
  width: 100%;
  object-fit: contain;
  height: 100%;
  margin-top: 9px;
  max-height: 40px;
}
/* Matches Tab */
.user-img-inner {
  border-radius: 15px !important;
  max-width: 70px !important;
  width: 100% !important;
  object-fit: cover;
  height: 60px;
}

.matches-all-wrap {
  :hover {
    background: rgb(223 223 223);
    // border-radius: 15px;
  }
  .matches-card-sec {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 378px;
    margin-left: 3px;
    position: relative;

    .matches-box-sec {
      padding: 25px 0px 0px 17px;

      .dog-img {
        min-width: 70px;
        height: 100%;
        background-color: transparent;
        svg {
          width: 70px;
          height: 70px;
        }
      }
      .matche-sec {
        padding-top: 6px;
        margin-left: 18px;
        border-bottom: 1px solid #c3c3c3;
        width: 65%;
        &:hover {
          // background: rgb(223, 223, 223);
          border-radius: 0px;
        }
        .matches-grid-sec {
          overflow-x: scroll;
          white-space: nowrap;
          .dog-details {
            border-right: 2px solid #000000;
            height: 20px;
            padding-left: 6.6px;
            padding-right: 8.4px;
            flex-shrink: 0;
          }
          .dog-details:first-child {
            padding-left: 0px;
          }
          .dog-details:last-child {
            border-right: none;
            padding-right: 0px;
          }
          .dog-name {
            font-weight: 700;
            padding-bottom: 7px;
          }
          .detail-content {
            padding-right: 10px;
            max-width: 25.13px;
            height: 18.6px;
          }
          .location {
            align-self: center;
          }
        }
      }
    }

    .matches-box-sec:last-child .matche-sec {
      border-bottom: none;
    }

    .matches-star-icon {
      background-color: transparent;
      cursor: pointer;
    }
  }
}
.star-sec {
  position: absolute;
  top: 0;
  right: 0;
}
/* Matches Tab */

/* Delete Dialog box  */

.delete-head-sec {
  padding: 0px !important;
  width: 262px !important;
  .favourites-delete-sec {
    border-bottom: 1px solid #a5a5a7;
    padding: 0px 16px 0px 16px;
    .dailog-head-text {
      font-size: 17px;
      line-height: 22px;
      font-family: $regulerFont;
      text-align: center;
      padding-top: 15px;
      font-weight: 600;
    }
  }
  .permission-btns {
    display: flex;
    .cancel-btn {
      border-right: 1px solid #a5a5a7;
      .favourites-cancel-btn {
        color: #54b5cd;
      }
    }
    .favourites-yes-btn {
      color: #f54d71;
    }
    .favourites-btn {
      font-size: 17px;
      line-height: 22px;
      font-family: $regulerFont;
      text-transform: capitalize;
      margin: 10px 0px 11px 0px;
    }
  }
  .MuiDialogContent-root {
    background-color: #d0d0d0;
    padding: 0px;
  }
  .MuiDialog-paper {
    border-radius: 15px;
  }
}
.p-9 {
  padding: 9px;
}
/* Delete Dialog box  */

/*  */
.blank-page-head-sec {
  padding-top: 42px;
  .dummy-img-sec {
    justify-content: center;
    padding-bottom: 56px;
    .dummy-img {
      width: 225.68px;
      height: 269.15px;
    }
    .places-dummy-img {
      width: 238.38px;
      height: 269.31px;
    }
    .benefit-dummy-img {
      width: 225.68px;
      height: 269.15px;
    }
  }
}

.map-data {
  background-color: #fff;
  position: absolute;
  bottom: 20px;
  padding: 10px;
  // height: 200px;
  // width: 200px;
  z-index: 99;
  margin: 0 20px;
  border-radius: 15px;
  .data-inner {
    padding-left: 20px;
  }
}
.match-bg {
  height: max-content;
  .dog-paw-icon {
    display: none;
  }
}
