@import 'src/assets/scss/variable.scss';

.payment-content {
  padding: 0 15px !important;
  @media (max-width: 425px) {
    width: 100vw;
  }
}
.payment-details-sec {
  padding-top: 43px;
  padding-bottom: 19px;
  .paw-pular-btn-sec {
    display: flex;
    justify-content: center;
    .paw-pular-btn {
      // margin-top: 24.4px;
      // margin-bottom: 40px !important;
      // width: auto !important;
      // font-family: Open Sans, sans-serif;
      // font-size: 15px;
      // line-height: 16px;
      // font-weight: 400;
      // text-transform: none;
      // background: $color-pink;
      padding: 4px 12px;
      border-radius: 20px;
      color: $color-white;
      z-index: 1;
      background: rgb(220, 34, 74);
      background: linear-gradient(
        0deg,
        rgba(220, 34, 74, 1) 0%,
        rgba(243, 76, 137, 1) 100%
      );
    }
  }

  .payment-details {
    text-align: center;
    margin: 0 31px;
    .per-month-text {
      font-family: Open Sans, sans-serif;
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      color: #f54d71;
      .per-month-text-amount {
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
      }
    }
    .card-details {
      padding-top: 17px;
    }
    .details-text {
      font-family: Open Sans, sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      color: #000000;
    }
    .font-bold {
      font-weight: 800;
    }
    .payment-card-image {
      padding-left: 10px;
      svg {
        padding-right: 6px;
        align-self: center;
      }
      svg:last-child {
        padding-right: 0;
      }
    }
    .card-form {
      padding-top: 39px;
      padding-bottom: 85px;
      .text-field-text-gray {
        color: #c3c3c3;
      }
    }
    .cvv-icon {
      align-self: center;
      margin-left: 6.5px;
      width: 20.2px;
      height: 15.76px;
    }
    .trial-text {
      font-size: 13.33px;
      line-height: 16px;
      font-family: RedHatRegular;
      font-weight: 400;
      color: #000000;
    }
  }
}
