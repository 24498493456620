@import 'src/assets/scss/variable.scss';

.sub-content {
  width: 414px;
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
  @media (max-width: 425px) {
    width: 100%;
  }
}
.dialog_title {
  padding: 0 !important;
}
.subscription-head-sec {
  position: relative;
  // width: 350px;

  .subscription-popup-sec {
    // transform: rotate(180deg);
    position: absolute;
    // right: -92px;
    // top: -8px;
    top: -6px;
    right: -36px;

    // @media (max-width: 900px) {
    //   right: -25px;
    // }
    // @media (max-width: 440px) {
    //   right: -25px;
    // }
    // @media (max-width: 430px) {
    //   right: -25px;
    // }

    .dog-img {
      width: 100%;
      height: 100%;
    }
    // img {
    //   // max-width: 283px;
    //   width: 100%;
    //   height: 189px;
    // }
  }
  .subscription-back-arrow {
    position: fixed;
  }
  .premium-btn-sec {
    display: flex;
    justify-content: center;
    // padding-top: 91px;
    .premium-btn {
      // width: 252px;
      // height: 28px;
      // text-transform: capitalize;

      padding: 4px 12px;
      border-radius: 20px;
      color: $color-white;
      z-index: 1;
      background: rgb(220, 34, 74);
      background: linear-gradient(
        0deg,
        rgba(220, 34, 74, 1) 0%,
        rgba(243, 76, 137, 1) 100%
      );
    }
  }
  .subscription-header-sec {
    text-align: center;
    .header-txt {
      font-size: 22px;
      line-height: 30px;
      font-family: $regulerFont;
      font-weight: 600px;
    }
    .sub-header-txt {
      font-size: 13px;
      line-height: 16px;
      font-family: $regulerFont;
      font-weight: 400;
    }
  }
  .subscription-instruction {
    display: flex;
    .instructions {
      font-size: 13px;
      line-height: 16px;
      font-family: $regulerFont;
      margin-left: 15px;
    }
    .cup-img {
      width: 18px;
      height: 18px;
      min-width: 18px;
    }
  }
  .slider-sec {
    .select-plan-txt {
      font-size: 15px;
      line-height: 17px;
      font-family: $regulerFont;
      text-align: center;
    }
  }
  .btn-instruction-sec {
    display: flex;
    justify-content: center;
    margin-top: 38px;
    .trial-btn {
      text-transform: capitalize;
      width: 345px;
      height: 38px;
    }
    .custom-button:nth-child(n) {
      margin-bottom: 0px;
    }
  }
  .instruction-txt {
    font-size: 13.33px;
    line-height: 16px;
    font-family: $regulerFont;
    text-align: center;
  }
  .agree-link {
    margin-top: 5px;
  }
}

.slide-card.active {
  // background-color: #f34c89;
  background-color: transparent;
}

.card-slider {
  // height: 425px;

  .slick-list {
    height: 147px !important;
  }

  .slide-card {
    width: 189px !important;
    // max-width: 300px;
    // width: 100%;
    height: 111px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    margin-top: 0px;
    border-radius: 15px;
    .card-content-sec {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .card-content {
        padding: 0px;
        .header-txt {
          font-size: 16px;
          line-height: 16px;
          font-family: $regulerFont;
          text-align: left;
          font-weight: 400;
          padding-top: 16.5px;
          padding-left: 8px;
        }
        .header-txt-checked {
          font-size: 16px;
          line-height: 16px;
          font-family: $regulerFont;
          text-align: left;
          font-weight: 400;
          // color: white;
          padding-top: 16.5px;
          padding-left: 8px;
        }
        .sub-header-txt {
          font-size: 13px;
          line-height: 16px;
          font-family: $regulerFont;
          font-weight: 400;
          color: #a7a7a7;
          text-align: left;
          padding-left: 10px;
          width: 175px;
        }
        .sub-header-txt-checked {
          font-size: 13px;
          line-height: 16px;
          font-family: $regulerFont;
          font-weight: 400;
          // color: #d4d6d8;
          color: #a7a7a7;
          text-align: left;
          padding-left: 10px;
          width: 175px;
        }
        .plan-duration {
          font-size: 12px;
        }
      }

      .Mui-checked {
        color: #54b5cd !important;
        border: 1px solid white;
        border-radius: 20px;
        background-color: white;
        // padding: 0px;
        // margin-right: 9px;
        padding: 9px;
        height: 42px;
        width: 42px;
      }
      .MuiCheckbox-root {
        margin-left: -28px;
        top: 6px;
      }
    }
    .slider-card-sec {
      position: absolute;
      .slider-card-btn {
        position: relative;
        // top: 25px;
        top: 20px;
        left: 13px;
        .card-btn {
          // width: 175px;
          // height: 33px;
          // margin-bottom: 0px;
          // text-transform: inherit;
          // color: $color-black;
          // font-weight: 400;
          font-weight: 400;
          background: #fdd2db;
          border-radius: 20px;
          text-align: center;
          padding: 4px 12px;
        }
        .active-plan-card-btn {
          top: 35px;
          left: 13px;
        }
      }
    }
    .active-plan {
      color: $color-pink;
      margin-left: 10px;
      display: flex;
      align-items: center;
      font-size: 9px;
      font-weight: 800;
      position: absolute;
      top: 6px;
      .active-plan-dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 2px;
        background: $color-pink;
      }
    }
  }
  .slick-slide {
    width: 205px !important;
    // height: 70px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .slick-dots {
    height: 44px;
    padding-top: 15px;
    li.slick-active {
      button:before {
        font-size: 10px !important;
        color: #f54d71 !important;
      }
    }
    li {
      margin: 0px;
      button:before {
        font-size: 10px !important;
      }
    }
  }
}

.subscription-link {
  color: #c3c3c3 !important;
}

.dialog-sub-box {
  // width: 409px;
  margin: 0 auto;
  .MuiBackdrop-root {
    width: 0;
  }
  .close {
    position: absolute;
    left: 90%;
    top: 2%;
    z-index: 999;
  }
  .MuiDialog-paper {
    margin: 0 auto;
    height: $_height;
    // max-width: calc(100% - 8%);
    // width: 100%;
    @media (max-width: 599px) {
      max-width: 100% !important;
    }
    @media (max-width: 425px) {
      height: 100%;
      max-width: 100% !important;
    }
  }
  .MuiDialogTitle-root {
    padding: 0;
  }
  .MuiDialog-paper {
    @media (max-width: 470px) {
      max-width: calc(100% - 28px) !important;
    }
    @media (max-width: 470px) {
      max-width: calc(100% - 19px) !important;
    }
    @media (max-width: 425px) {
      max-width: calc(100% - 0px) !important;
    }
  }
}
.subscription-modal {
  .MuiPaper-root {
    max-width: 355px !important;
  }
}
.subscription-alert-modal {
  padding-top: 35px;
  .sub-alert-text {
    font-size: 17px;
    font-weight: bold;
  }
}
