.login-field-content {
  display: grid;
  grid-template-columns: 104px auto;
  gap: 19px;
}
// .MuiSelect-select {
//   .dropdown-flag-text {
//     font-weight: 600;
//     font-size: 22px;
//   }
// }
.dropdown-flag-icon {
  margin-right: 10px;
  height: 20px;
  width: 30px;
}
.dropdown-flag-text {
  font-weight: 400;
  font-size: 16px;
}
