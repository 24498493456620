@import '../../../../assets/scss/variable.scss';

.img-upload-head-sec {
  position: relative;
  display: grid;
  justify-content: center;
  .img {
    padding: 15px 0px 15px 0px;
    .img-upload-camera {
      width: 100px;
      height: 100px;
      cursor: pointer;
      margin-top: -80px;
      border-radius: 50%;
    }
  }
  .img-upload-sec {
    .img-card-edit-btn {
      background-color: $color-white;
      box-shadow: none;
      position: absolute;
      left: 46%;
      bottom: 0px;
      border-radius: 26px;
      width: 34px;
      height: 34px;
      padding: 2px;
      .upload-img {
        // width: 15.81px;
        // height: 15.81px;
        cursor: pointer;
        // margin-left: 9px;
        // margin-top: 7px;
      }
    }
  }
}

.dummy-img-sec {
  position: absolute;
  display: inline-flex;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  margin-top: -80px;
  .dummy-img {
    width: 200px;
    overflow: hidden;
    .circle-img-dummy {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }
}
