@import '../../../../assets/scss/variable.scss';

/* Dog Details */

// .MuiPopover-paper {
//   height: 120px;
//   overflow-y: scroll;
// }

/* Dog Picture */

/* Dog Video */

.video-card {
  margin-top: 0px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  height: 217px;
  width: 310px;
  margin-left: 3px;
  position: static;
}
.video-upload-icon {
  width: 100px;
  height: 100px;
}
.video-remove-icon {
  top: 50px;
  left: 158px;
}
.VideoInput_input {
  height: 217px;
  width: 310px;
}
.image-wrapper.selected {
  position: relative;
}

.selected-icon {
  position: absolute;
  top: 22px;
  right: 23px;
  padding: 5px;
  border-radius: 50%;
}
.video-box {
  width: 84px;
  height: 84px;
  object-fit: cover;
  border-radius: 10px;
}
.video-cam-icon {
  position: absolute;
  top: 40%;
  right: 30%;
}

/* Dog Video */
.dog-age-block {
  .MuiFormControl-root {
    width: 100%;
    fieldset {
      border: 0;
    }
    .MuiInputBase-input {
      padding: 10px 0 2px 0;
      border-bottom: 1px solid;
      font-size: 13px;
    }
  }
}
.dog-badge-icon {
  height: 20px;
  width: 20px;
  margin: 0 3px;
}
.dog-label-card {
  background: $color-white;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  padding: 5px 5px 5px 10px;
  margin: 5px 3px !important;
  border-radius: 8px;
  .radio-icon {
    height: 16px;
    width: 16px;
  }
}
