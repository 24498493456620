@import 'src/assets/scss/variable.scss';

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-header {
  background-color: #262626;
  overflow: visible;
  width: 100%;
  text-align: center;
  color: white;
}
.chat-wrap {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.Messages-list {
  padding: 5px;
  margin: 0 auto;
  list-style: none;
  flex-grow: 1;
  overflow: auto;
  // height: calc(100vh - 80px);
  // background: url('../../../../assets/images/bg-img.png') left top repeat;
  background-repeat: round;
  overflow-y: scroll;
  &.with-form {
    // height: calc(100vh - 160px);
    // height: 58vh;
    // padding-bottom: 10px;
    margin-bottom: 70px;
  }
  &.with-form-multiline {
    height: calc(100vh - 261px);
  }
  &.with-form.changeHeight {
    // height: calc(100vh - 261px);
    margin-bottom: 150px;
  }
  &.bgcover {
    background-size: cover !important;
  }
  @media (max-width: 767px) {
    // padding: 20px;
    ul {
      padding-left: 0;
    }
  }
}

.Messages-message {
  display: flex;
  margin-top: 10px;
}

.Messages-message.currentMember {
  /*justify-content: flex-end;*/
  flex-direction: row-reverse;
  text-align: right;
}

.Message-content {
  display: inline-block;
}

.currentMember > .Message-content {
  align-items: flex-end;
}

.Messages-message > .avatar {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px -10px;
  padding: 10px;
  background: #e6e6e6;
  border: 1px solid #47d730;

  img {
    // margin: 1px;
    border-radius: 50%;
  }
}

.Message-content {
  .send-date {
    font-size: 10px;
    text-align: right;
    color: #000;
  }
  & > .username {
    display: block;
    color: #000;
    font-weight: bold;
    text-align: left;
    font-size: 17px;
    line-height: 20px;
    padding-bottom: 4px;
  }
  // .message-text {
  //   color: #ffffff;
  // }

  & > .text {
    a {
      color: #5f6ebf;
      text-decoration-color: #5f6ebf;
    }
    .icon-button {
      position: absolute;
      right: 3px;
      top: 0;
      display: none;
      background-color: #f0f0f0;
    }
    .show_Button.icon-button {
      display: block;
    }
    .dropdown-content {
      width: 140px;
      position: absolute;
      top: 90%;
      left: 95%;
      background: #ffffff;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }
    button {
      position: relative;
    }
    svg {
      width: 15px;
      height: 15px;
      color: #000000;
    }
    & > .reply-msg {
      svg {
        color: #888b8d;
      }
    }
  }
  & > .text:hover .icon-button {
    display: block;
    svg {
      color: #0000008a;
    }
  }
  & > .text:hover .delivered-msg {
    display: none;
  }
  & > .text {
    position: relative;
    padding: 5px;
    max-width: 300px;
    margin: 0;
    text-align: left;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #000;
    display: inline-block;
    // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &,
    p,
    ul,
    li {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & > .messagedeletedstyle {
    display: flex;
    align-items: center;
    color: '#000000';
    background: none !important;
    box-shadow: none;
  }
  & > .messagedeletedstyle:hover .icon-button {
    display: none;
  }
}
.message-seen {
  display: flex;
  justify-content: flex-end;
  .seen-text {
    color: $color-gray44;
    text-align: right;
    font-size: 12px;
    line-height: 20px;
    padding-right: 2px;
  }
  svg {
    align-self: center;
  }
}
.Messages-message {
  &.system-messages {
    text-align: center;
    display: block;

    & > .Message-content {
      .text {
        font-size: 14px;
        line-height: 18px;
        padding: 5px 20px;
        & .delivered-msg,
        button {
          display: none;
        }
      }
    }
  }
  &.system-messages {
    & > .Message-content > .username,
    & > .Message-content > .date,
    & > .Message-content > .send-date,
    & > .avatar {
      display: none;
    }
  }
  &.deleted-message {
    .seenby {
      display: none;
    }
  }
  &.currentMember {
    & > .Message-content > .username,
    & > .avatar {
      display: none;
    }
  }

  &.currentMember {
    & > .Message-content {
      .text {
        background-color: #f54d71;
        color: #fff;
      }
    }
    & > .type-emoji {
      // text-align: center;
      // display: block;
      // font-size: 15px;
      // margin: 0;
      .text {
        box-shadow: none;
        background-color: transparent;
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .seenby {
    color: #888b8d;
    font-size: 15px;
  }
  &.type-systemtime {
    text-align: center;
    display: block;
    font-size: 15px;
    margin: 0;
  }
  .replay-contact-title {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.type-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  svg {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
}
.type-emoji {
  // text-align: center;
  // display: block;
  // font-size: 15px;
  // margin: 0;
  .text {
    // box-shadow: none;
    // background: transparent;
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.type-missed_call {
  text-align: center;
  width: 100%;
  .text {
    max-width: 100%;
    box-shadow: none;
    background: transparent;
  }
}

.Messages-wrap {
  position: relative;
  // height: calc(100vh - 64px - 64px);
  .load-more-btn {
    width: auto;
    margin: 10px auto;
  }
}
.chat-content {
  .scroll-down {
    background: #000000;
    position: absolute;
    right: 20px;
    bottom: 75px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    padding: 0;
    color: #ffffff;
    opacity: 0.5;
  }
  .scroll-down:hover {
    background: #000000;
  }
}
.chat-form {
  position: absolute !important;
  bottom: 0;
  left: 0;
  &.MuiPaper-root {
    // box-shadow: none;
    background: #f5f6f8;
    z-index: 1;
  }
  form {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    max-width: 100%;
    padding: 10px 15px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  input,
  textarea {
    padding: 4px 15px;
    // margin: 0 15px;
    font-size: 16px;
    border-radius: 22px;
    flex-grow: 1;
    height: auto;
    background: #ffffff;
    // line-height: 19px;
    min-height: 28px;
    max-height: 70px;
    overflow-y: auto !important;
    border: 1px solid #c3c3c3;
  }
  .MuiButton-root {
    // padding: 7px 12px;
    padding: 7px;
    min-width: 40px;
  }
  .message-input {
    width: 100%;
    // margin: 0 10px 0 0;
    margin: 0;
    position: relative;
    // height: 54px;
    .MuiInputBase-root {
      padding: 5px 0;
    }
    fieldset {
      border-width: 0 !important;
    }
    .MuiInput-multiline {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0 !important;
    }
  }
  .add-button {
    color: #0047bb;
    padding: 5px 0;
  }
  // .sent-button {
  //   background: #44d62c;
  //   color: #ffffff;
  // }
  .add-button:hover {
    background: transparent;
  }
}

.message-menu {
  .MuiListItem-button {
    border-bottom: 1px solid #f0f0f0;
  }
  .MuiListItem-button .MuiListItemIcon-root,
  .MuiListItem-button .MuiListItemIcon-root svg {
    font-size: 16px;
    width: 20px;
  }
  .MuiListItemText-root span {
    font-size: 16px;
  }
  .MuiButtonBase-root {
    padding-top: 0px;
    padding-bottom: 2px;
  }
}
.Messages-wrap {
  position: relative;

  &.show_overlay {
    &:before {
      position: absolute;
      content: '';
      //top: -10px;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100%);
      //height: calc(100% + 10px);
      z-index: 0;
      background: rgba(0, 0, 0, 0.3);
    }
    .Messages-message {
      padding-left: 65px;
    }
    // .Messages-message .avatar {
    //   //display: none;
    // }
  }
}
.Messages-message {
  position: relative;
  .checkbox-blue {
    position: absolute;
    left: 0;
    top: 25%;
    .MuiCheckbox-colorPrimary {
      border-radius: 50%;
      border: 1px solid #707070;
      background: #fff;
      width: 10px;
      height: 10px;
      &.Mui-checked {
        background: '#3b66c4';
        border-color: #fff;
      }
      .MuiIconButton-label {
        display: none;
      }
    }
  }
}
.forward-icon {
  transform: scaleX(-1);
}
.replay-form {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #95989a;
  .alert-box {
    width: 93%;
    display: flex;
    justify-content: space-between;
  }
  .MuiIconButton-root {
    &:hover {
      background-color: transparent;
    }
  }
  .message-content {
    width: 80%;
    // width: 100%;
    .sender-message {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
.reply-msg {
  margin-bottom: 5px;

  .sender-name {
    color: #f54d71;
    font-size: 14px !important;
    padding-bottom: 5px;
  }
  .sender-message {
    font-size: 14px;
    color: #888b8d;
    // img {
    //   width: 35px;
    // }
  }
  .MuiAvatar-root {
    border-radius: 5px;
  }
  .reply-imageWrap {
    width: 90px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .reply-image {
    width: 65px;
    height: 50px;
    // margin-right: -18px;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    &.sticker {
      width: 40px;
      height: 40px;
    }
  }
}
.Message-content {
  // word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}
.Message-content .reply-msg {
  display: block;
  // width: 90%;
}
.Message-content > .text .message-forwared {
  svg {
    color: #d4d6d8;
    width: 14px;
    height: 14px;
  }
  color: #888b8d;
  margin: 0 0 10px;
  font-size: 14px;
}
.alert-box {
  border-width: 0;
  border-radius: 10px;
  border-color: #f54d71;
  border-style: solid;
  padding: 5px 10px;
  background: #ffffff;
  &.left-border {
    border-left-width: 10px;
  }
  .contact-letter {
    margin: -5px -20px -5px 20px;
    border-radius: 0 10px 10px 0;
    padding: 0 25px;
  }
}
.small-caption {
  font-size: 12px !important;
  line-height: 13px !important;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50vw;
  @media screen and (max-width: 776px) {
    width: 100px;
  }
}
.contact-share-main button {
  background: transparent;
  text-transform: capitalize;
  .MuiButton-label {
    font-size: 16px;
  }
}
.chatuser-action-dropdown {
  ul {
    position: absolute;
    margin: 0;
    background: '#d0d0d0';
    border-radius: 5px;
    right: 30px;
    z-index: 111;
    top: 45px;
    padding: 5px 15px;
  }
  li {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 1px solid #ccc;
    &:hover,
    &:active {
      box-shadow: none;
      background: none !important;
    }
    &:last-child {
      border-bottom: none;
    }
    button {
      background-color: transparent;
      box-shadow: unset;
      color: #000;
      border-radius: unset;
      padding: 0;
      width: 100%;
      .MuiButton-label {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
      }
      .MuiSvgIcon-root {
        width: 20px;
        padding-right: 5px;
      }
      &:hover,
      &:focus {
        background: transparent;
        box-shadow: none;
      }
      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}
// AUDIO RECORDING
.audio-recording-continer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .audio-recording-timer {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #d4d6d8;
    width: 100%;
    padding: 5px;
    text-align: center;
  }
}
.react-player-audio {
  width: 290px !important;
  height: 60px !important;
}
.audio-player-wrapper {
  display: flex;
  cursor: pointer;
  padding-bottom: 7px;
  .MuiAvatar-root {
    width: 45px;
    height: 45px;
  }
  .audio-icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .audio-icon.send {
    margin-left: 12.8px;
  }
  .audio-icon.received {
    margin-right: 12.8px;
  }
  .voice-icon.send {
    position: absolute;
    left: 36px;
    z-index: 1;
    bottom: 13px;
  }
  .voice-icon.received {
    position: absolute;
    right: 3px;
    z-index: 1;
    bottom: 13px;
  }
}
.audio-player-wrapper .react-player-audio {
  pointer-events: none;
}

.audio-msg-dialog {
  .MuiPaper-root {
    max-width: 345px;
  }
}
