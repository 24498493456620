@import 'src/assets/scss/variable.scss';

.link-request-sec{
    .MuiPaper-root{
        max-width: 345px;
    }
    .head-text {
        font-size: 22px;
        line-height: 30px;
        font-family: $regulerFont;
        font-weight: 400;
        color: $color-black;
        .head-text-bold{
            font-weight: 700;
        }
    }
    .dog-img-card {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 25.5px;
        .dog-img {
          margin-top: 0px;
          border: 1px solid white;
          border-radius: 50%;
          box-shadow: 0px 3px 6px #00000029;
          height: 90px;
          width: 90px;
          object-fit: cover;
          max-width: 100px;
        }
    }
    .content-sec{
        border-top: 1px solid #c3c3c3;
        border-bottom: 0;
        padding-top: 21.5px;
        padding-bottom: 12px;
        .content-text{
            font-size: 12px;
            line-height: 15px;
            font-family: $regulerFont;
            font-weight: 400;
            color: $color-black;
            .text-bold{
                font-weight: 700;
            }
        }
    }
    .contact-card {
        padding-top: 35px;
        .card-image {
          width: 90px;
          height: 90px;
          svg {
            width: 90px;
            height: 90px;
            transform: scale(1.4);
          }
        }
        .card-content {
          border-bottom: 1px solid #c3c3c3;
          .card-msg {
            font-size: 20px;
            line-height: 30px;
            font-family: $regulerFont;
            color: $color-black;
          }
          .person-name {
            font-weight: 700;
          }
        }
        .btn-sec {
          .link-btn {
            background-color: #f54d71;
            font-size: 15px;
            line-height: 17px;
            font-family: $regulerFont;
            margin: 33px 0px 40px 0px;
            border-radius: 25px;
            max-width: 134px;
            width: 100%;
            height: 36px;
          }
        }
      }
}