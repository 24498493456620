@import '../../../../../assets/scss/variable.scss';

.edit-dog-profile-sec {
  .edit-dog-accordian-box-sec {
    box-shadow: 0px 3px 6px #00000029;
    max-width: 379px;
    margin: -12px 0px 25px 3px;
    border-radius: 10px;
    padding: 29px 10px 20px 10px;
  }

  .dog-accordian-content-sec {
    .dog-accordian-tab-sec {
      cursor: pointer;
      justify-content: space-between;
      .dog-name {
        font-size: 13px;
        line-height: 18px;
        font-family: $regulerFont;
        font-weight: 700;
      }
      .content-errow {
        align-self: center;
      }
      .dog-list-img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}

.edit-dog-accordian-tab-sec:active {
  background-color: #f0f0f0;
}

/* Dog Profile Detail */

.make-my-domain-sec {
  justify-content: center;
  .make-my-domain-txt {
    font-size: 15px;
    line-height: 20px;
    font-family: $regulerFont;
    font-weight: 400;
    padding-left: 9px;
    align-self: center;
  }
}

.dog-profile-detail-sec {
  .done-btn-sec {
    justify-content: center;
    .done-btn {
      width: 134px;
      height: 36px;
    }
  }
  .header-txt {
    font-size: 20px;
    line-height: 27px;
    font-family: $regulerFont;
    font-weight: 600;
    padding-top: 10px;
  }
  .form-content {
    .MuiInput-root:before {
      border-bottom: 1px solid #c3c3c3;
    }
  }
  .dog-profile-detail {
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    margin: 0px 3px 20px 3px;
    height: auto;
    padding: 0 13px 5px 13px;

    .dog-name-edit p {
      line-height: 17px;
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }
  .dog-avatar-sec {
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    margin: 0px 3px 28px 3px;
    height: auto;
    padding: 10px;
    justify-content: space-between;
    .dog-avatar-txt {
      font-size: 12px;
      line-height: 17px;
      font-family: $regulerFont;
      font-weight: 400;
    }
    .dog-avatar-img {
      width: 16.3px;
      height: 16px;
    }
  }
}

.dog-breed-select {
  border-bottom: 1px solid #c3c3c3;
}

.edit-upgrade-button {
  margin-top: -33px;
}

/* Dog Profile Detail */
