@import '../../../../assets//scss/variable.scss';

.landing-main-container {
  font-family: Poppins;
  width: 100%;
  margin: auto 0;
  height: calc(100vh - 20%);
  @media (max-width: 768px) {
    padding: 0px 30px;
  }
  @media (max-width: 425px) {
    padding: 0px 18px;
    height: calc(100vh - 6%);
  }
  .landing-card-container {
    background: #fafafa;
    width: 509px;
    // height: 836px;
    border-radius: 26px;
    float: right;
    margin-right: 12.5%;
    padding: 45px;
    text-align: center;
    height: calc(100vh - 25%);
    overflow: auto;
    max-height: 836px;
    @media (max-width: 991px) {
      margin: auto;
      float: none;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0px;
    }
    @media (max-width: 425px) {
      padding: 27px;
      height: calc(100vh - 6%);
    }
    .landing-card-wrapper {
      .landing-card-title {
        font-family: Poppins;
        margin-top: 21.52px;
        font-size: 15px;
        letter-spacing: 4.5px;
        color: #212121;
        font-weight: 400;
      }
      .landing-card-sub-title {
        font-family: Poppins;
        margin-top: 3.18px;
        font-size: 11px;
        letter-spacing: 3.3px;
        color: #e7345e;
        padding-bottom: 21.11px;
        font-weight: 400;
      }
      .ads-partner-desc-content {
        margin-top: 37.24px;
        margin-bottom: 27px;
        .ads-partner-desc {
          font-family: Poppins;
          font-size: 18px;
          letter-spacing: 0;
          color: #000000;
          font-weight: 400;
        }
        .ads-partner-drawn-date {
          font-family: Poppins;
          font-size: 18px;
          letter-spacing: 0;
          color: #000000;
          font-weight: 600;
        }
      }
    }
    .login-field-content {
      display: grid;
      grid-template-columns: 104px auto;
      gap: 19px;
    }
    .ads-partner-form-container {
      .landing-form-submit-btn {
        margin-bottom: 0px;
        @media (max-width: 425px) {
          max-height: fit-content;
        }
      }
    }
    .landing-field {
      margin-bottom: 32px;
    }
    .landing-grid-field {
      display: grid;
      grid-template-columns: auto auto;
      gap: 19px;
      @media (max-width: 425px) {
        grid-template-columns: auto;
        gap: 0px;
      }
    }
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  display: none;
}
.Toastify__toast-container--bottom-center {
  left: inherit;
  transform: inherit;
  right: 18%;
  @media (max-width: 991px) {
    left: 50%;
    transform: translateX(-50%);
  }
}
