@import './variable.scss';
body {
  font-family: $regulerFont;
  .pointer {
    cursor: pointer;
  }
  .isDisabled {
    cursor: not-allowed;
  }
  .page-content-wrap {
    position: relative;
    // padding: 43px 15px 15px 15px;
    // height: calc(100% - 20%);
    // overflow: auto;
    z-index: 1;
    // height: 100vh;
    // .auth-content {
    // text-align: center;
    // height: 100%;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // flex-direction: column;
    // padding-top: 43px;
    // }
  }

  // .content-scroll {
  //   overflow: hidden;
  //   position: sticky;
  //   top: 0px;
  //   background: #fafafa;
  //   z-index: 9;
  //   height: inherit;
  // }

  // .looking-for-chip {
  //   overflow: auto;
  //   max-height: 227px;
  //   @media only screen and (max-width: 425px) {
  //     overflow: auto;
  //     max-height: 402px;
  //   }
  //   @media only screen and (min-width: 1900px) {
  //     overflow: auto;
  //     max-height: fit-content;
  //   }
  // }

  .main-page-content-wrap {
    position: relative;
    // height: calc(100vh - 60px);
    overflow: auto;
    height: inherit;
  }

  // .looking-for-scroll {
  // position: relative;
  // height: inherit;
  // overflow: hidden;
  // }
  .your-childern-box {
    overflow-y: scroll;
    height: 100%;
    max-height: 363px;

    @media only screen and (min-width: 1900px) {
      height: auto;
      overflow: visible;
    }
  }
  a {
    color: $color-white;
  }
  .d-block {
    display: block;
  }
  .d-flex {
    display: flex;
  }
  .t-center {
    text-align: center;
  }
  .m-auto {
    margin: auto;
  }
  .o-auto {
    overflow: auto;
  }
  .o-hidden {
    overflow: hidden;
  }
  .mr-9 {
    margin-right: 9px;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mb-11 {
    margin-bottom: 55px;
  }
  .mb-10 {
    margin-bottom: 50px;
  }
  .mb-9 {
    margin-bottom: 45px;
  }
  .mb-8 {
    margin-bottom: 40px;
  }
  .mb-7 {
    margin-bottom: 35px;
  }
  .mb-6 {
    margin-bottom: 30px;
  }
  .mb-5 {
    margin-bottom: 25px;
  }
  .mb-4 {
    margin-bottom: 20px;
  }
  .mb-3 {
    margin-bottom: 15px;
  }
  .mb-2 {
    margin-bottom: 10px;
  }

  .mt-16 {
    margin-top: 80px;
  }
  .mt-14 {
    margin-top: 70px;
  }
  .mt-10 {
    margin-top: 50px;
  }
  .mt-9 {
    margin-top: 45px;
  }
  .mt-8 {
    margin-top: 40px;
  }
  .mt-7 {
    margin-top: 35px;
  }
  .mt-6 {
    margin-top: 30px;
  }
  .mt-5 {
    margin-top: 25px;
  }
  .mt-4 {
    margin-top: 20px;
  }
  .mt-3 {
    margin-top: 15px;
  }
  .mt-2 {
    margin-top: 10px;
  }
  .m-4 {
    padding: 8px;
  }

  .pb-39 {
    padding-bottom: 210px;
  }
  .pb-36 {
    padding-bottom: 195px;
  }
  .pb-26 {
    padding-bottom: 145px;
  }
  .pb-23 {
    padding-bottom: 130px;
  }
  .pb-20 {
    padding-bottom: 100px;
  }
  .pb-18 {
    padding-bottom: 90px !important;
  }
  .pb-16 {
    padding-bottom: 80px;
  }
  .pb-14 {
    padding-bottom: 70px;
  }
  .pb-12 {
    padding-bottom: 60px;
  }
  .pb-10 {
    padding-bottom: 50px;
  }
  .pb-9 {
    padding-bottom: 45px;
  }
  .pb-8 {
    padding-bottom: 40px;
  }
  .pb-7 {
    padding-bottom: 35px;
  }
  .pb-6 {
    padding-bottom: 30px;
  }
  .pb-5 {
    padding-bottom: 25px;
  }
  .pb-4 {
    padding-bottom: 20px;
  }
  .pb-3 {
    padding-bottom: 15px;
  }
  .pb-2 {
    padding-bottom: 10px;
  }
  .pb-1 {
    padding-bottom: 5px;
  }

  .pt-130 {
    padding-top: 305px;
  }
  .pt-18 {
    padding-top: 90px;
  }
  .pt-2 {
    padding-top: 10px;
  }
  .p-0 {
    padding: 0px;
  }
  .s-bold {
    font-weight: 600;
  }
  .regular-font {
    font-weight: 400;
  }
  .bold {
    font-weight: 700;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .inherit {
    text-transform: inherit;
  }
  .color-error {
    color: $color-red !important;
  }
  .color-pink {
    color: $color-pink !important;
  }
  .disable-color {
    color: $color-gray44;
  }
  .border {
    border: 1px solid $border;
  }
  .b-border {
    border-bottom: 1px solid $color-black;
  }
  .p-absolute {
    position: absolute;
  }
  .w-100 {
    width: 100%;
  }
  .bottom-0 {
    bottom: 0;
  }
  .top-0 {
    top: 0;
  }
  .height {
    height: 100vh;
  }
  .txt-elips-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-textfield {
    width: 100%;
    .MuiInput-root {
      font-family: $regulerFont;
    }
    .MuiFormHelperText-root.Mui-error {
      color: $color-red;
      font-family: $regulerFont;
      font-size: 10px;
    }
  }
  .opacity {
    opacity: 0.5;
  }
  .round {
    height: 10px;
    width: 10px;
    background-color: $color-black;
    border-radius: 20px;
    display: block;
    margin: 0 6px 0 6px;
  }
  .custom-select {
    font-family: $regulerFont;
    height: 32px;
    .MuiSelect-select {
      border-bottom: 1.5px solid $color-black;
      background-color: transparent;
      display: flex;
      justify-content: left;
      align-items: center;
      height: -webkit-fill-available;
      min-height: auto;
    }
    .MuiSelect-icon {
      top: auto;
      color: $color-black;
      margin-right: 9px;
    }
    .Mui-error {
      color: $color-red;
      font-family: $regulerFont;
      font-size: 10px;
    }
    .MuiList-root {
      max-height: 250px;
    }
  }

  .select-options {
    font-family: $regulerFont;
  }
  .form-content {
    width: 100%;
    .field-content {
      width: 100%;
    }
  }
  .custom-field-helper {
    color: $color-black;
    font-family: $regulerFont;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }
  .checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiCheckbox-root {
      padding: 0;
    }
  }
  .custom-date-picker {
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    .MuiInputBase-input {
      border-bottom: 1px solid $color-black;
      padding: 5px 0;
      font-weight: 800;
    }
    .desc-dob {
      margin-right: 40px;
    }
    .education-font {
      font-size: 17px;
    }
  }
  .custom-radio {
    width: 100%;
    .MuiFormControlLabel-root {
      justify-content: space-between;
      margin: 0;
    }
    .MuiRadio-root {
      padding: 0;
    }
  }
  .progress-bg-img {
    position: relative;
    img {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    @media only screen and (max-width: 479px) {
      position: initial;
    }
  }
  .font-r-10 {
    font-size: 10px;
    font-family: $regulerFont;
    font-weight: 400;
    color: $color-black;
  }
  .font-r-11 {
    font-size: 11px;
    font-family: $regulerFont;
    font-weight: 400;
    color: $color-black;
  }
  .font-r-12 {
    font-size: 12px;
    font-family: $regulerFont;
    font-weight: 400;
    color: $color-black;
  }
  .font-r-13 {
    font-size: 13px;
    font-family: $regulerFont;
    font-weight: 400;
    color: $color-black;
  }
  .font-r-14 {
    font-size: 14px;
    font-family: $regulerFont;
    font-weight: 400;
    color: $color-black;
  }
  .b-radius-10 {
    border-radius: 10px;
  }
  .text-danger {
    color: red;
  }
  .picture-upload-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(84px, 1fr));
    gap: 20px;
    .grid-cell {
      width: 84px;
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      margin: auto;
      position: relative;
      .preview-img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .remove-button {
        position: absolute;
        top: -8px;
        right: -8px;
      }
    }
  }
  .custom-label {
    margin-right: 0;
  }
  .text-decor {
    text-decoration: none;
  }
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  // Chip Container

  // .user-education {
  // .chip-box {
  // overflow: hidden;
  // margin: 0 -28px;
  // overflow: hidden;
  // margin-right: -28px;
  // margin-left: -28px  ;
  // }
  // }

  .chip-block {
    display: flex;
    width: 100%;
    overflow: auto;
    .MuiChip-deleteIcon {
      color: $color-black;
      &:hover {
        color: $color-black;
      }
    }
    .chip-inner {
      margin: 0px 3px 5px 3px;
      background-color: $color-white;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
      width: 100%;
    }
    .chip-active {
      background-color: $color-pink-accent;
    }
  }
  // .file-uploader {
  //   transition: transform 0.2s ease-in-out;
  // }
  // PICTURE BOX
  .lot-Images-block {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 33px;
    justify-content: space-between;
  }
  .lot-Images-block.img-main-block {
    margin: 0 15px;
    @media (max-width: 375px) {
      margin: 0 10px;
      column-gap: 15px;
    }
  }
  .lot-Images-block.img-main-flow-block {
    margin: 0 10px;
    @media (max-width: 375px) {
      margin: 0 5px;
      column-gap: 15px;
    }
  }
  .text-left {
    text-align: left;
  }
  .picture-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
  .profile-picture {
    .picture-upload {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      .file-upload-sec {
        // padding-left: 17px;
        // @media (max-width: 425px) {
        //   padding-left: 0;
        // }
        background: $color-white;
        .card {
          margin-top: 0px;
          border: 1px solid white;
          border-radius: 10px;
          box-shadow: 0px 3px 6px #00000029;
          height: 84px;
          width: 84px;
          // margin-left: 3px;
          @media (max-width: 320px) {
            height: 80px;
            width: 80px;
            // margin-left: 0;
          }
          .MuiAvatar-root {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          .remove-image {
            position: absolute;
            top: -17px;
            right: -14px;
            padding: 0;
            z-index: 11;
          }
          .file-upload-box {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .file-upload-input {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              opacity: 0;
              cursor: pointer;
            }
            .file-upload-img {
              width: 31.5px;
              height: 31.5px;
              // margin-top: 21px;
            }
          }
        }
      }
      .not-clickable {
        cursor: default;
      }
      .picture-add-block {
        padding-left: 0px;
      }
    }
  }
  .img-attach-box {
    margin-bottom: 25px;
    .file-atc-box {
      position: relative;
      .file-image {
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
      }
      .file-action-btn {
        font-size: 12px;
        color: $color-black;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        top: -13px;
        left: 84px;
        .close-icon {
          height: 31.5px;
          width: 31.5px;
        }
      }
    }
  }
  .file-image img {
    height: 85px;
    width: 89px;
    border-radius: 9px;
    border: 1px solid transparent;
  }
  .file-atc-box:last-child {
    margin-bottom: 0;
  }
  .align-center {
    align-items: center;
  }
  .align-self {
    align-self: center;
  }
  .justify-center {
    justify-content: center;
  }
  .space-between {
    justify-content: space-between;
  }
  .pink-outline-btn {
    border-radius: 11px;
    font-size: 10px;
    padding: 2px 13px;
    max-height: 22px;
    // text-transform: inherit;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    font-family: Open Sans, sans-serif;
    text-transform: capitalize;
    border: 1px solid $color-pink;
  }
  .custom-btn {
    width: auto;
    margin: auto;
  }
  .b-bottom {
    border-bottom: $border-bottom;
  }
  .b-top {
    border-top: $border-top;
  }
  .l-height {
    line-height: 14px;
  }

  .chip-looking-for-box {
    position: sticky;
    // top: calc(100% - 345px);
    top: 0;
    background: rgb(250, 250, 250);
    z-index: 9;
    overflow: auto;
    @media only screen and (max-width: 425px) {
      top: 0;
    }
    @media only screen and (min-width: 1900px) {
      top: 0;
    }
  }
  .edit-your-profile-box {
    position: sticky;
    bottom: 0%;
    background: $background-color;
    z-index: 9;
    overflow: auto;
    padding-top: 15px;
  }
  .chip-container-box {
    position: sticky;
    top: 0;
    background: $background-color;
    z-index: 9;
    overflow: auto;
  }
  .chip-container {
    width: 100%;
    .MuiChip-deleteIcon {
      color: $color-black;
      &:hover {
        color: $color-black;
      }
    }
    .chip-inner {
      margin: 0px 3px 5px 3px;
      background-color: $color-white;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    }
    .border-chip-inner {
      margin: 0px 3px 5px 3px;
      background-color: $color-white;
      border: 1px solid $border;
    }
    .chip-active {
      background-color: $color-pink-accent;
    }
  }
  .autocomplete-dropdown-container {
    .MuiMenuItem-root {
      white-space: unset;
    }
    .suggestion-item,
    .suggestion-item--active {
      font-size: 18px;
      font-family: $regulerFont;
      display: block;
      text-align: left;
      border-bottom: 1px solid #707070;
      padding: 6px 0px;
      line-height: 24px;
      cursor: pointer;
    }
  }
  .select-option-wrap {
    width: 100%;
    height: $_height;
    background: #00000073;
    position: fixed;
    top: 12.5%;
    left: 0px;
    max-width: 414px;
    right: 0;
    margin: 0 auto;
    z-index: 1111;
    @media (max-width: 425px) {
      height: $_height_mobile;
      top: 0;
    }
  }
  .custom-dialog-yes-no-box {
    width: 300px;
  }
  .profile-hobby {
    .MuiFormControlLabel-root {
      @media only screen and (max-width: 379px) {
        margin-left: 0px;
      }
    }
  }

  .profile-ethnicity {
    .MuiFormControlLabel-root {
      @media only screen and (max-width: 390px) {
        margin-left: 0px;
      }
    }
  }

  .edit-your-profile {
    position: relative;
    bottom: 12.5%;
    // background: $color-white;
    // padding: 12px;
    max-width: 413px;
    width: 100%;
    // margin: auto -15px;

    @media (max-width: 425px) {
      bottom: 0;
    }
  }

  .btn-fixed-bottom {
    position: relative;
    // position: fixed;
    bottom: 12.5%;
    // background: $color-white;
    padding: 12px;
    max-width: 413px;
    width: 100%;
    margin: auto -15px;
    @media (max-width: 425px) {
      bottom: 0;
    }
  }
  .date-picker {
    .react-datepicker-wrapper {
      width: 100%;
    }
    .date-picker-input {
      border: 0;
      border-bottom: 1px solid $color-black;
      width: 100%;
      background-color: #fafafa;
      font-family: $regulerFont;
      font-weight: 700;
      outline: none;
      font-size: 17px;
    }
  }
  .local-call-video {
    // position: fixed;
    // max-width: 414px;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(207px, 1fr));
    // overflow: auto;
    // height: $_height;
    // height: $_height;
    // display: flex;
    height: 100%;
    overflow: hidden;
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .remote-video,
    .local-video {
      height: 50%;
      background: $color-black;
    }
    .local-full-video {
      height: 100%;
    }
  }
  .video-call-btn {
    // background: rgb(0 0 0 / 32%);
    position: absolute;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .video-not-allow-msg {
    position: absolute;
    top: 75%;
    z-index: 1;
    color: $color-white;
    width: 100%;
    text-align: center;
  }
  // TOASTIFY
  .Toastify__toast-container {
    .Toastify__toast-theme--colored.Toastify__toast--info {
      background-color: $color-blue;
    }
    .Toastify__toast-theme--colored.Toastify__toast--success {
      background-color: #59d09d;
    }
    .Toastify__toast-theme--colored.Toastify__toast--warning {
      background-color: #f1c40f;
    }
    .Toastify__toast-theme--colored.Toastify__toast--error {
      background-color: #d84727;
    }
    .Toastify__toast-body {
      padding: 0;
    }
    .Toastify__toast {
      word-break: break-word;
      border-radius: 23px;
      div {
        font-family: $regulerFont;
        font-size: 15px;
      }
      .Toastify__close-button {
        display: none;
        opacity: 1;
        color: $color-white;
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  .draggable {
    touch-action: none;
  }
}
.dog-age-block-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid;
  .dog-month {
    width: 160px;
  }
}

.divider {
  border: 1px solid $color-gray-accent;
}
.plus-icon {
  padding-left: 16px;
  cursor: pointer;
}

.done-btn-sec {
  .done-btn {
    width: 134px;
    height: 36px;
    margin: auto;
  }
}
.horizontal-scroll {
  // user-select: none;
  // cursor: grab;
  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $color-gray;
    border-radius: 20px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $color-gray;
    border-radius: 20px;
  }
}

// CALL SCREEN
.call-screen-head-sec {
  background-image: url('../../assets/images/call-img.png');
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-size: cover;
  background-size: 100% 100%;
  // max-width: 414px;
  // height: 100%;
  width: 100%;
  height: $_height;
  position: fixed;
  top: 12.5%;
  left: 0px;
  max-width: 414px;
  right: 0;
  margin: 0 auto;
  padding: 0;
  @media (max-width: 425px) {
    height: $_height_mobile;
    top: 0;
  }
  .call-screen {
    height: 100%;
    overflow: auto;
    .call-screen-sec {
      background-color: rgba(105, 105, 105, 0.7);
      text-align: center;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      .person-name {
        // font-size: 27px;
        // line-height: 36px;
        color: $color-white;
        // font-family: $regulerFont;
        // font-weight: 400;
        padding-top: 88px;
      }
      .person-img {
        width: 175px;
        height: 174px;
        border-radius: 50%;
        svg {
          max-width: 175px;
          width: 100%;
          height: 174px;
          transform: scale(1.4);
        }
      }
      .call-action-sec {
        justify-content: space-evenly;
        .call-action-icons {
          width: 52px;
          height: 52px;
          svg {
            width: 43.42px;
            height: 32.35px;
          }
        }
        .call-end-icon {
          width: 52px;
          height: 52px;
          background-color: transparent;
        }
      }
    }
  }
}

.overflow {
  overflow: auto;
}
.checkbox-container {
  .custom-checkbox-text {
    .start-talk-checkbox {
      margin-left: 14px;
    }
  }
}

.about-location {
  overflow-y: scroll;
  max-height: 402px;
  height: 100%;
  @media only screen and (max-width: 960px) {
    max-height: 324px;
  }
  @media only screen and (max-width: 425px) {
    max-height: 513px;
  }
  @media only screen and (min-width: 1900px) {
    height: 100%;
    max-height: inherit;
  }
}

.about-location-button {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.location-search-input {
  .MuiInputBase-root {
    &:before {
      border-bottom-style: inherit !important;
    }
  }
  .MuiInputBase-input {
    border-bottom: 1px solid $color-black;
  }
}
