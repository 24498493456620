@import 'src/assets/scss/variable.scss';
body {
  .s-font-20 {
    font-size: 20px;
    font-weight: 600;
    font-family: $regulerFont;
  }
  .dog-profile-wrap {
    display: block;
    margin-top: 80px;
  }
  .continue-btn-sec {
    display: flex;
    justify-content: center;

    .continue-btn {
      text-transform: capitalize;
      width: auto;
      max-height: 22px;
      font-size: 10px;
      padding: 4px 16px 4px 16px;
    }
  }
  .bottom-text-wrap {
    position: relative;
  }
  .profile-bg {
    background: $color-white;
    padding: 20px 16px 27px 13px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 16%);
    border-radius: 10px;
  }
}
