// @import '../../../assets/scss/variable.scss';

@import '../../../../../assets//scss/variable.scss';
.page-content-wrap {
  .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.chip-data {
  margin-bottom: 5px !important;
}
.field-sec {
  overflow: auto;
  height: 49vh;
  // max-height: 150px;
}
.field-profile-sec {
  overflow: auto;
  height: 20vh;
}
.keep-going-sec {
  width: 100%;
  // position: absolute;
  // bottom: 0;
  margin-bottom: 10px;
}
.yes-no-sec {
  justify-content: space-around;
  align-items: center;
  .yes-no-btn {
    width: 90px;
    height: 30px;
    justify-content: center;
  }
}

.profile-footer-dog-img {
  max-width: 81.32px;
  width: 100%;
  height: 61.36px;
}
