@import 'src/assets/scss/variable.scss';

.directory-map-sec {
  position: relative;
  .map-place-details {
    position: absolute;
    bottom: 30px;
    z-index: 999;
    @media only screen and (max-width: 479px) {
      bottom: 47px;
    }
    .map-place-details-sec {
      width: 90%;
      margin: 0 auto;
      background-color: #ffffff;
      padding: 24px 18px;
      border-radius: 10px;
      z-index: 999999;
      position: relative;

      @media only screen and (max-width: 479px) {
        height: 221px;
      }
    }
    .map-places {
      display: flex;
    }
    .place-details-left {
      width: 36px;
      margin-right: 9px;
      .place-image {
        width: 36px;
        height: 36px;
      }
    }

    .place-details-right {
      width: 100%;
      .rating-sec {
        svg {
          color: #f54d71;
        }
      }
      .place-name {
        font-size: 18px;
        line-height: 24px;
        font-family: $regulerFont;
        font-weight: 600;
        color: $color-black;
      }
      .sponsored-name {
        font-size: 13px;
        line-height: 18px;
        font-family: $regulerFont;
        font-weight: 400;
        color: $color-black;
      }
      .visit-link {
        color: #f54d71;
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset: 2px;
      }
      .f-bold {
        font-weight: 600;
      }
      .color-green {
        color: #59d09d;
      }
      .rating-details {
        display: flex;
      }
      .place-address {
        font-size: 16px;
        line-height: 22px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #707070;
      }
      .place-status {
        font-size: 12px;
        line-height: 16px;
        font-family: $regulerFont;
        font-weight: 400;
      }
      .fav-bene-button {
        // background-color: #F54D71;
        background-color: #ffffff;
        color: #000000;
        font-size: 16px;
        line-height: 22px;
        font-family: $regulerFont;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 0;
        margin-top: 31px;
        max-width: 120px;
        // box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 3px 6px #00000029;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
        .button-text {
          justify-content: start;
        }
      }
      .pink-button {
        background-color: #f54d71;
        color: #ffffff;
      }
    }
    .fav-benefit-button-sec {
      display: flex;
      justify-content: space-around;
    }
    .fav-benefit-button-sec.fav-button-sec {
      justify-content: center !important;
    }
    .mr-9 {
      margin-right: 9px;
    }
  }
  .benefit-map-btn {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 200px;
  }
}
