@import 'src/assets/scss/variable.scss';

.landing-screen-bg {
  background-image: url('../../assets/images/adpartner/wollemi-retreat-bg.webp');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
