@import "../../../../assets/scss/variable.scss";

.contact-list-head-sec {
  background-color: #f4f4f4;
  position: relative;

  .header-text {
    font-size: 20px;
    list-style: 27px;
    margin-left: 15px;
    font-family: $regulerFont;
  }

  .search-bar {
    // max-width: 345px;
    max-width: 93%;
    height: 36px;
    border-radius: 12px;
    border: 1px solid $border;
    box-shadow: none !important;
    margin: 0 auto 15px;

    .search-contact-textfield {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      font-family: $regulerFont;

      .MuiInputBase-input {
        padding-top: 7px;
      }
    }

    .search-icon {
      margin-right: 8px;
      margin-left: 13px;
      width: 14.02px;
      height: 14.02px;
    }
  }

  .contact-char {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

  }

  .contact-list-order {
    // position: absolute;
    // right: 0;
    // top: 12%;
    padding-right: 3px;
    background-color: $color-white;

    // background-color: transparent;
    .list-name {
      font-size: 11px;
      line-height: 15px;
      font-family: $regulerFont;
      color: $color-pink;
      padding: 0px;
      min-width: 0px;
    }
  }

  .contact-list-sec {
    position: relative;
    overflow: scroll;
    height: 78vh;

    .contact-list {
      width: 100%;

      .contact-list-name {
        font-size: 17px;
        line-height: 22px;
        font-family: $regulerFont;
        color: $color-black;
        font-weight: 600;
        padding-left: 15px;
        background-color: $color-white;
      }

      .person-deatil {
        padding: 11px 0px 0px 0px;
        margin: 0 15px;

        .person-image {
          height: 40px;
          width: 40px;
          margin-left: 15px;

          svg {
            height: 40px;
            width: 40px;
            transform: scale(1.4);
          }
        }

        .person-name-sec {
          margin-left: 18px;
          width: 70%;
          border-bottom: 1px solid #c3c3c3;
          padding: 10px 0px 16px 0px;
          cursor: pointer;

          .person-name {
            font-size: 16px;
            line-height: 17px;
            font-family: $regulerFont;
            color: $color-black;
            font-weight: 600;
            align-self: center;
          }
        }
      }
    }

    .person-deatil:last-child {
      padding-bottom: 11px;
    }

    .person-deatil:last-child .person-name-sec {
      border-bottom: none;
    }

  }
}

.contact-card {
  padding-top: 35px;

  .card-image {
    width: 90px;
    height: 90px;

    svg {
      width: 90px;
      height: 90px;
      transform: scale(1.4);
    }
  }

  .card-content {
    border-bottom: 1px solid #c3c3c3;

    .card-msg {
      font-size: 20px;
      line-height: 30px;
      font-family: $regulerFont;
      color: $color-black;
    }

    .person-name {
      font-weight: 700;
    }
  }

  .btn-sec {
    .link-btn {
      background-color: #f54d71;
      font-size: 15px;
      line-height: 17px;
      font-family: $regulerFont;
      margin: 33px 0px 40px 0px;
      border-radius: 25px;
      max-width: 134px;
      width: 100%;
      height: 36px;
    }
  }
}