@import '../../../../assets/scss/variable.scss';

.web-keyboard {
  // position: relative;
  // min-height: 92vh;

  width: 100%;
  height: $_height;
  position: fixed;
  top: 12.5%;
  left: 0px;
  max-width: 414px;
  right: 0;
  margin: 0 auto;
  @media (max-width: 425px) {
    height: $_height_mobile;
    top: 0;
  }

  .keyboard-sec {
    background-color: #f5f6f8;
    border-top: 1px solid #e8e8e8;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    .keyboard-menu-bar {
      // padding: 10px;
      min-width: 0;
    }
    .type-box-sec {
      .type-bar-textfield {
        border-radius: 25px;
        border: 1px solid #c3c3c3;
        box-shadow: none !important;
        width: 100%;
        // height: 29px;
        min-width: 197px;
        align-self: center;
        margin-right: 12px;
        .MuiInputBase-input {
          padding-left: 8px;
        }
      }
      .focus-textfield {
        min-width: 285.38px;
        border-radius: 25px;
        border: 1px solid #c3c3c3;
        box-shadow: none !important;
        width: 100%;
        // height: 29px;
        align-self: center;
        margin-right: 15px;
        margin-left: 15px;
        .MuiInputBase-input {
          padding-left: 8px;
        }
      }
      .MuiInputBase-formControl {
        width: 100%;
        height: 29px;
        border-radius: 25px;
        border-color: #c3c3c3;
      }
      .MuiInputLabel-formControl {
        top: -15px;
      }
      // .heart-btn {
      //   align-self: center;
      //   padding: 0;
      //   min-width: 0;
      // }
      .send-icon {
        margin-right: 15px;
        margin-left: -10px;
        .chat-send-icon {
          min-width: 0px;
          width: 32px;
          height: 32.99px;
          align-self: center;
          background-color: transparent;
          svg {
            width: 32px;
            height: 32.99px;
          }
        }
      }
    }
    .arrow-icon {
      padding-left: 18px;
      .chat-arrow-icon {
        min-width: 0px;
        width: 13.5px;
        height: 23.62px;
        align-self: center;
        background-color: transparent;
        svg {
          width: 13.5px;
          height: 23.62px;
        }
      }
    }
  }
}
.border-sec {
  cursor: pointer;
  padding-top: 8px;
  .bottom-border {
    border-bottom: 5px solid #c3c3c3;
    border-radius: 25px;
    width: 54px;
    max-width: 50%;
  }
}
/* Emoji Tab */
.sticker-head-sec {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 0px;
  z-index: 1;
  .sticker-tab-sec {
    background-color: #f5f6f8;
    .MuiTabs-indicator {
      display: none;
    }
    .sticker-search-sec {
      width: 100%;
      display: flex;
      padding: 16px 0 8px;
      .done-button {
        text-transform: capitalize;
        font-size: 15px;
        font-family: $regulerFont;
        font-weight: 800;
        line-height: 20px;
        color: $color-pink;
      }
      .sticker-input {
        // position: absolute !important;
        // bottom: 0;
        // left: 0;
        width: 100%;
        margin: 0 !important;
        &.MuiPaper-root {
          box-shadow: none;
        }
        form {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-start;
          max-width: 100%;
          padding: 10px 15px;
          margin: 0 auto;
          box-sizing: border-box;
        }
        input {
          padding: 4px 0 5px !important;
        }
        .MuiInputBase-root,
        textarea {
          padding: 4px 15px;
          margin: 0 0 0 15px;
          font-size: 16px;
          border-radius: 22px;
          flex-grow: 1;
          height: auto;
          background: #ffffff;
          line-height: 28px;
          min-height: 28px;
          max-height: 50px;
          overflow-y: auto !important;
        }
        .MuiButton-root {
          // padding: 7px 12px;
          padding: 7px;
          min-width: 40px;
        }
        fieldset {
          border-width: 0 !important;
        }
      }
    }
    .sticker-tab {
      border: 1px solid transparent;
      border-radius: 20px;
      padding: 0px;
      min-width: 100px;
      min-height: 36px;
      border: 1px solid #f5f4f4;
      background-color: #fafafa;
      margin-right: 2px;
      margin-left: 3px;
      color: #000;
      text-transform: capitalize;
      font-size: 15px;
      line-height: 20px;
      font-family: $regulerFont;
      font-weight: 400;
    }
    .Mui-selected {
      color: #fafafa !important;
      background-color: #f54d71 !important;
    }
    .MuiTabs-flexContainer {
      column-gap: 27px;
      padding-top: 9px;
      justify-content: center;
    }

    .MuiTabs-scroller {
      overflow: inherit !important;
    }
    .tab-list-sec {
      overflow: inherit;
      // border-top: 1px solid #e8e8e8;
    }
  }
}
// .main-tab-panel {
//   position: relative;
//   z-index: 1;
//   background-color: #f5f6f8;
// }
.emoji-tab-sec {
  column-gap: 13px;
  row-gap: 10px;
  padding: 0px !important;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  .emoji-icon-box {
    margin-top: 0px;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    height: 50px;
    width: 50px;
    // width: 20%;
    max-width: 50px;
    .emoji-icons {
      align-self: center;
      text-align: center;
      background-color: transparent;
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
  .emoji-tabs {
    width: 100%;
    padding-bottom: 9px;
    .emoji-tab {
      padding: 0px;
      opacity: 1;
      min-width: 0px;
      min-height: 0px;
      width: 20%;
      max-width: 40px;
    }
  }
}
.search-bar-sec {
  background-color: #f5f6f8;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .search-bar-textfield {
    max-width: 278px;
    width: 100%;
    height: 29px;
    border-radius: 25px;
    border: 1px solid white;
    box-shadow: none !important;
    .search-text {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      font-family: $regulerFont;
    }
    .search-icon {
      margin-right: 7px;
      margin-left: 13px;
      width: 13.02px;
      height: 13.02px;
    }
  }
  .done-btn {
    font-size: 15px;
    line-height: 20px;
    font-family: $regulerFont;
    padding: 0px;
    padding-left: 17px;
  }
}

/* Emoji Tab */
