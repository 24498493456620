@import '../../../../assets/scss/variable.scss';

.crop-image {
  margin-top: 50px;

  .cropper-modal {
    // background-color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
  }

  .crop-save-btn {
    // display: flex;
    // justify-content: space-between;
    padding: 18px 0 0;
    border-top: 1px solid #e7e9ec;
    .btn-gray {
      color: $color-black !important;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;

      .btn {
        min-width: 100%;
      }

      .b-btn-blue {
        margin-top: 18px;
      }

      .ml-18 {
        margin-top: 18px;
        margin-left: 0 !important;
      }
    }
  }

  .crop-image-top {
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .without-crop {
    width: 98%;
    overflow: hidden;
    margin: 0 auto;

    .cropper-container {
      margin: 0 auto !important;
    }
  }
  .cropper-crop-box {
    width: 303px !important;
    height: 202px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    // margin: 0 auto;
  }
  .cropped-image {
    width: 300px;
    overflow: hidden;
    margin: 0 auto;

    .cropper-crop-box {
      transform: none !important;
    }

    .cropper-container {
      max-width: 100% !important;

      .cropper-canvas {
        display: none !important;
      }

      .cropper-crop-box {
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
    }

    // .cropper-container .cropper-crop-box {
    //     left: 50% !important;
    //     top: 50% !important;
    //     transform: translate(-50%, -50%) !important;
    // }
  }

  .zoom-plus-minus {
    display: flex;

    .disabled-btn {
      cursor: default;
      background-color: $color-white !important;
    }

    .icon-btn {
      width: 20%;
      padding: 0;
    }

    .zoom-bar {
      width: 80%;
      align-self: center;

      input {
        width: 100%;
      }
    }
  }
  .all-block-wrap {
    display: flex;
    justify-content: center;
  }
}
// .custom-button {
//   max-width: 303px;
//   height: 36px;
// }
.crop-details {
  text-align: center;
  padding: 18px 18px;
  border-bottom: 1px solid #e7e9ec;
  .crop-title-text {
    font-size: 22px;
    margin-bottom: 9px;
  }
  .crop-title-desc {
    font-size: 14.99px;
    font-weight: 400;
  }
}

.profile-cropper {
  .cropper-view-box {
    border-radius: 10px;
    // overflow: hidden;
    outline: 3px dashed $color-pink;
    // max-width: 303px !important;
    // max-height: 202px !important;
    // box-sizing: border-box;
    // max-width: 98% !important;
    // margin: 0 auto !important;
    img {
      // width: 303px !important;
      // height: 202px !important;
      // transform: translate(0%, 0%) !important;
    }
  }
  .cropper-dashed {
    border: none;
  }
  // .cropper-face {
  //   border-radius: 50%;
  // }
}

.crop-dialog-box {
  max-width: 100%;
  //
  .MuiPaper-root {
    max-width: calc(100% - 10px) !important;
    width: 339px;
  }
  .dialog_content {
    padding: 0px;
    height: 520px; //698px;
    .crop-image {
      height: 520px; //698px;
    }
    @media (max-height: 698px) {
      max-height: 420px;
    }
    -webkit-overflow-scrolling: inherits;
    .next-btton-sec {
      button {
        width: 303px;
      }
    }
  }
}
