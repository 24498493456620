@import '../../../../../assets/scss/variable.scss';

body {
  .edit-profile-card {
    display: block;
    margin-top: -30px;
    padding: 16px 27px 27px;
    // position: absolute;
    // top: 103px;
    // right: 0;
    // left: 0;
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 16%);
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 10px;
    //   background-color: #fff;
    //   z-index: -1;
    // }
  }
}
