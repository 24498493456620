@import '../../../../assets/scss/variable.scss';

.notification-head-sec {
  .account-type-sec {
    border-bottom: 1px solid #c3c3c3;
    .custom-button {
      text-transform: capitalize;
      width: 164px;
      max-height: 56px;
    }
    .custom-button:nth-child(n) {
      margin-bottom: 23px;
    }
  }
  .switch-sec-grid {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    .left-content-grid {
      padding-left: 0px;
      padding-top: 0px;
      .switch-title {
        font-size: 13px;
        line-height: 18px;
        font-family: $regulerFont;
        font-weight: 700;
      }
      .switch-desvc {
        font-size: 13px;
        line-height: 18px;
        font-family: $regulerFont;
        font-weight: 400;
      }
    }
    .right-btn-grid {
      align-self: center;
      padding-top: 0px;
    }
  }
}
