@import 'src/assets/scss/variable.scss';

.custom-dialog {
  ::-webkit-scrollbar {
    width: 0;
  }
  .css-hz1bth-MuiDialog-container {
    height: auto !important;
  }
  .MuiDialog-paper {
    // box-shadow: none;
    // max-width: 414px;
    // height: 100vh;
    // width: 100%;
    // padding: 30px 1px 0px 0px;
    // text-align: center;
    // align-items: center;
    // margin: 0;
    // padding: 15px 12px;

    width: 100%;
    height: $_height;
    background: $color-white;
    position: fixed;
    top: 12.5%;
    left: 0px;
    max-width: 414px;
    right: 0;
    margin: 0 auto;
    z-index: 1111;
    @media (max-width: 425px) {
      max-height: $_height_mobile;
      height: $_height_mobile;
      top: 0;
      max-width: 100%;
    }

    // @media (max-width: 599px) {
    //   padding: 15px 12px;
    // }
    // @media (max-width: $mobileBreakPoint) {
    //   margin: 0;
    //   // position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   min-height: 100vh;
    //   min-width: 100%;
    //   padding-top: 30px;
    //   overflow-x: hidden;
    //   padding-bottom: 0;
    // }
    .MuiDialogTitle-root {
      padding: 0;
    }
    .imagre-box {
      padding: 15px;
    }
    .dialog-save {
      position: absolute;
      top: 15px;
      right: 15px;
      .save-btn {
        font-size: 10px;
      }
    }
    .dialog-content-box {
      .dialog-title-sec {
        position: relative;
      }
      .dialog-close {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
      }
      .custom-dialog-title {
        position: relative;
        .dialog-title {
          width: 100%;
          font-size: 22px;
          line-height: 30px;
          // color: $color-white;
          // font-family: $authHeaderFont;
          text-align: center;
          //   text-transform: uppercase;
          // margin-bottom: 40px;
          font-weight: 600;
          color: $color-black;
          font-family: $regulerFont;
          // margin-top: -18px;

          // @media (max-width: 599px) {
          //   font-size: 26px;
          //   margin-top: 19.5px;
          // }

          // svg {
          //   @media (max-width: 599px) {
          //     width: 15px;
          //     height: 15px;
          //   }
          // }
        }
      }
      .save-btn {
        width: 60px;
        height: 24px;
      }
    }
    // @media (max-width:480px){
    //   margin: 0%;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   min-height: calc(100% + 16px);
    //   width: 100%;
    //   padding-top: 0;
    //   overflow-x: hidden;
    //   .dialog-content-box {
    //     position:sticky;
    //     top:0;
    //     padding-top:10px;
    //     z-index: 2;
    //     background-color: #171717;
    //     width: 100%;
    //     margin-bottom:10px;
    //   }
    //   .dialog-submit-container {
    //     position: sticky;
    //     bottom: 0;
    //     background-color: #171717;
    //     padding-top: 0;
    //     margin-top: 20px;
    //     z-index: 2;
    //   }
    //   .dialog-submit-button {
    //     padding-bottom:25px;
    //     margin-top: 0;
    //   }
    // }
  }
}
// .claim-profile-dialog {
// @media (max-width: $mobileBreakPoint) {
//   .MuiDialog-paper {
//     margin: 0;
//     min-width: 100%;
//     min-height: 100%;
//     .custom-dialog-title {
//       .dialog-title {
//         text-align: start;
//       }
//     }
//   }
//   .dialog-submit-button {
//     position: fixed;
//     bottom: 0;
//     width: 100%;
//     margin-left: -12px;
//     background-color: #171717;
//     padding: 16px 36px;
//   }
// }
// }
